import {
  ChakraProvider,
  Text,
  VStack,
  Heading,
  Stack,
  HStack,
  Skeleton,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  SimpleGrid,
  Tbody,
  Center
} from "@chakra-ui/react"
import Chart from 'react-apexcharts'
import { Header } from "./components/Header"
import { useSearchParams } from "react-router-dom";
import { theme } from "./Theme";
import axios from 'axios';
import { useEffect, useState } from "react"
import { API_ROOT } from './assets/const';
import { UsageInfo, Customer, Graph } from './assets/types';
import { CopyButton } from "./components/CopyButton";
import { getApexChartsOptions, RedirectToLogin } from "./assets/utils";
import { ServiceTypeImage } from "./components/ServiceTypeImage";
import { TextWithCopyButton } from "./components/TextWithCopyButton";

export const UsageStatus = () => {


  const [searchParams,] = useSearchParams();
  const customer_id = searchParams.get("customer_id");
  const [email, setEmail] = useState<string>("");

  const [customerData, setCustomerData] = useState<Customer | null>(null);
  const [usageStatus, setUsageStatus] = useState<UsageInfo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [kumamushiV1GraphData, setKumamushiV1GraphData] = useState<Graph | null>(null);
  const [kumamushiV2GraphData, setKumamushiV2GraphData] = useState<Graph | null>(null);
  const [hacGraphData, setHacGraphData] = useState<Graph | null>(null);
  const [hacSyncLogGraphData, setHacSyncLogGraphData] = useState<Graph[]>([]);
  const [nobitaAmountData, setNobitaAmountData] = useState<Graph | null>(null);
  const [fzeroFilterData, setFzeroFilterData] = useState<Graph | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {

    axios.post(`${API_ROOT}/whoami`, {
      withCredentials: true
    })
      .then(response => {
        setLoading(false);
        setEmail(response.data)
        axios.get<Customer>(`${API_ROOT}/customers/${customer_id}`)
          .then((response) => {
            setCustomerData(response.data);
          }).catch(error => {
            console.log(error);
          });
        axios.get(`${API_ROOT}/usage_status/${customer_id}`)
          .then((response) => {
            setUsageStatus(response.data)
            setIsLoading(false)
            setHacGraphData(response.data.hac.chart ? {
              options: getApexChartsOptions(
                "shobosso",
                "HAC usage",
                ['#00E395', '#ff4560'],
                response.data.hac.chart.categories
              ),
              series: [{
                name: 'success',
                type: 'column',
                data: response.data.hac.chart.success_count
              },
              {
                name: 'fail',
                type: 'column',
                data: response.data.hac.chart.fail_count
              }]
            } : null);

            const sync_log_list: Graph[] = [];

            for (const domain_name of Object.keys(response.data.hac.sync_log_chart)) {
              const sync_log_data = response.data.hac.sync_log_chart[domain_name]
              const sync_log_types: string[] = ["other", "azure"]
              sync_log_types.forEach(function (sync_log_type, _) {
                if (!sync_log_data[sync_log_type as keyof typeof sync_log_data]) {
                  return;
                }
                let title = `${domain_name}: HAC sync log`;
                if (sync_log_type === "azure") {
                  title += " (Azure)"
                }
                sync_log_list.push({
                  options: getApexChartsOptions(
                    "hac-sync-log-azure",
                    title,
                    ["#3399FF", "#33FF99", "#FEB019", "#FF4560"],
                    sync_log_data[sync_log_type as keyof typeof sync_log_data].categories,
                  ),
                  series: [{
                    name: 'added',
                    type: 'column',
                    data: sync_log_data[sync_log_type as keyof typeof sync_log_data].added
                  },
                  {
                    name: 'updated',
                    type: 'column',
                    data: sync_log_data[sync_log_type as keyof typeof sync_log_data].updated
                  },
                  {
                    name: 'deleted',
                    type: 'column',
                    data: sync_log_data[sync_log_type as keyof typeof sync_log_data].deleted
                  },
                  {
                    name: 'failed',
                    type: 'column',
                    data: sync_log_data[sync_log_type as keyof typeof sync_log_data].failed
                  }]
                })
              });
            }
            setHacSyncLogGraphData(sync_log_list);

            setNobitaAmountData(response.data.nobita.chart ? {
              options: getApexChartsOptions(
                "nobita-amount",
                "Archive amount",
                ["#3399FF", "#33FF99", "#FEB019", "#FF4560"],
                response.data.nobita.chart.categories
              ),
              series: [
                {
                  name: 'bsmtpd',
                  data: response.data.nobita.chart.bsmtpd
                },
                {
                  name: 'jsmtpd',
                  data: response.data.nobita.chart.jsmtpd
                },
                {
                  name: 'rsmtpd_in',
                  data: response.data.nobita.chart.rsmtpd_in
                },
                {
                  name: 'rsmtpd_out',
                  data: response.data.nobita.chart.rsmtpd_out
                },
              ]
            } : null);
            setFzeroFilterData(response.data.fzero.chart ? {
              options: getApexChartsOptions(
                "fzero",
                "DLP usage",
                ["#3399FF", "#33FF99", "#FEB019", "#FF4560"],
                response.data.fzero.chart.categories
              ),
              series: [
                {
                  name: 'Send',
                  data: response.data.fzero.chart.send
                },
                {
                  name: 'Suspend',
                  data: response.data.fzero.chart.suspend
                },
                {
                  name: 'Request Approval',
                  data: response.data.fzero.chart.request_approval
                },
                {
                  name: 'Delete',
                  data: response.data.fzero.chart.delete
                },
              ]
            } : null);
            setKumamushiV1GraphData(response.data.kumamushi_v1.chart ? {
              options: getApexChartsOptions(
                "kumamushi_v1_graph",
                "HST v1 usage",
                ["#3399FF", "#33FF99"],
                response.data.kumamushi_v1.chart.categories
              ),
              series: [
                {
                  name: 'sbundle count',
                  data: response.data.kumamushi_v1.chart.sbundle_count
                },
                {
                  name: 'rbundle count',
                  data: response.data.kumamushi_v1.chart.rbundle_count
                },
              ]
            } : null);
            setKumamushiV2GraphData(response.data.kumamushi_v2.chart ? {
              options: getApexChartsOptions(
                "kumamushi_v2_graph",
                "HST v2 usage",
                ["#3399FF", "#33FF99", "#FEB019"],
                response.data.kumamushi_v2.chart.categories
              ),
              series: [
                {
                  name: 'HSD / Send',
                  data: response.data.kumamushi_v2.chart.secure_download_send
                },
                {
                  name: 'HST / Send',
                  data: response.data.kumamushi_v2.chart.secure_transfer_send
                },
                {
                  name: 'HST / Receive',
                  data: response.data.kumamushi_v2.chart.secure_transfer_receive
                },
              ]
            } : null);
          }).catch(error => {
            setIsLoading(false)
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          RedirectToLogin();
        }
      });
  }, [customer_id]);


  if (loading) {
    return <>Loading...</>
  }

  return (
    <ChakraProvider theme={theme}>
      <Header user_email={email} />
      <Stack fontSize="md" px={40} py={8} spacing={10} pt={20}>
        <Stack fontSize="md" spacing={4}>
          <HStack>
            <Heading as="h1" size="xl" color="grey.700">{customer_id}</Heading>
            {customer_id ? <CopyButton text={customer_id} /> : <></>}
          </HStack>
          <HStack>
            <Heading size="md" color="grey.700">
              {customerData ? customerData.service_id :
                <Skeleton width={120} height={6} />}
            </Heading>
            {customerData ? <CopyButton text={customerData.service_id} /> : <></>}
          </HStack>
          <HStack>
            <Heading size="md" color="grey.700">
              {customerData ? customerData.company_name_ja :
                <Skeleton width={150} height={6} />}
            </Heading>
            {customerData ? <CopyButton text={customerData.company_name_ja} /> : <></>}
          </HStack>
          <HStack>
            <Heading size="md" color="grey.700">
              {customerData ? customerData.company_name_en :
                <Skeleton width={180} height={6} />}
            </Heading>
            {customerData ? <CopyButton text={customerData.company_name_en} /> : <></>}
          </HStack>
        </Stack>
        <Center><Heading>Usage Status</Heading></Center>
        <SimpleGrid columns={2} spacing={8}>
          <Stack shadow="md" bg="white" p={4} spacing={4}>
            <Heading as="h2" size="md" p={4}>
              HENNGE Secure Browser <br /> HENNGE Device Certificate
            </Heading>
            {isLoading ?
              <Center><Skeleton mb={8} width={400} height={8} /></Center> :
              usageStatus ? usageStatus.hsb_hdc_count ?
                <Table>
                  <Thead>
                    <Tr><Th>Domain</Th><Th>HSB count</Th><Th>HDC count</Th></Tr>
                  </Thead>
                  <Tbody>
                    {Object.entries(usageStatus.hsb_hdc_count).map(
                      (domain_and_count_dict, index) =>
                        <Tr key={index}>
                          <Td><TextWithCopyButton text={domain_and_count_dict[0]} /></Td><Td><Center>{domain_and_count_dict[1]["hsb"]}</Center></Td><Td><Center>{domain_and_count_dict[1]["hdc"]}</Center></Td>
                        </Tr>
                    )}
                  </Tbody>
                </Table> : <Text>No data</Text> :
                <Text>Oops! Something went wrong.</Text>}
          </Stack>
          <Stack shadow="md" bg="white" p={4} spacing={4}>
            <Heading as="h2" size="md" p={4}>
              SSO
            </Heading>
            {isLoading ?
              <Center><Skeleton mb={8} width={400} height={100} /></Center> :
              usageStatus ? usageStatus.service_type ?
                <Table>
                  <Thead>
                    <Tr><Th>Domain</Th><Th>SSO Service</Th></Tr>
                  </Thead>
                  <Tbody>
                    {Object.entries(usageStatus.service_type).map(
                      (domain_and_service_type_dict, index) =>
                        <Tr key={index}>
                          <Td><TextWithCopyButton text={domain_and_service_type_dict[0]} /></Td><Td><Center><ServiceTypeImage service_type={domain_and_service_type_dict[1]} /></Center></Td>
                        </Tr>
                    )}
                  </Tbody>
                </Table> : <Text>No data</Text> :
                <Text>Oops! Something went wrong.</Text>}
          </Stack>
          <Stack shadow="md" bg="white" p={4} spacing={4}>
            <Heading as="h2" size="md" p={4}>
              HENNGE Access Control
            </Heading>
            {isLoading ?
              <Center><Skeleton mb={8} width={300} height={220} /></Center> :
              usageStatus ? <VStack> {hacGraphData ?
                <Chart options={hacGraphData.options} series={hacGraphData.series} type="bar" width={330} height={220} />
                : <Text>No data</Text>}
                {
                  hacSyncLogGraphData ?
                    hacSyncLogGraphData.map((item, index) => <Chart key={index} options={item.options} series={item.series} type="bar" width={330} height={220} />)
                    : <Text>No data</Text>
                }
              </VStack> :
                <Text>Oops! Something went wrong.</Text>}
          </Stack>
          <Stack shadow="md" bg="white" p={4} spacing={4}>
            <Heading as="h2" size="md" p={4}>
              HENNGE Email DLP
            </Heading>
            {isLoading ?
              <Center><Skeleton mb={8} width={300} height={220} /></Center> :
              usageStatus ? <VStack> {fzeroFilterData ?
                <Chart options={fzeroFilterData.options} series={fzeroFilterData.series} type="bar" width={330} height={220} />
                : <Text>No data</Text>}
                {usageStatus.fzero.last_days ? <Table variant='simple' backgroundColor="white">
                  <Thead>
                    <Tr><Th>Domain</Th><Th>Last date</Th></Tr>
                  </Thead>
                  <Tbody>
                    {Object.entries(usageStatus.fzero.last_days).map(
                      (domain_and_last_used_date_dict, index) =>
                        <Tr key={index}>
                          <Td><TextWithCopyButton text={domain_and_last_used_date_dict[0]} /></Td><Td>{domain_and_last_used_date_dict[1]}</Td>
                        </Tr>
                    )}
                  </Tbody>
                </Table> : <></>}
              </VStack> :
                <Text>Oops! Something went wrong.</Text>}
          </Stack>
          <Stack shadow="md" bg="white" p={4} spacing={4}>
            <Heading as="h2" size="md" p={4}>
              HENNGE Email Archive
            </Heading>
            {isLoading ?
              <Center><Skeleton mb={8} width={300} height={220} /></Center> :
              usageStatus ? <VStack> {nobitaAmountData ?
                <Chart options={nobitaAmountData.options} series={nobitaAmountData.series} type="bar" width={330} height={220} />
                : <Text>No data</Text>}
                {usageStatus.nobita.last_days ? <Table variant='simple' backgroundColor="white">
                  <Thead>
                    <Tr><Th>Domain</Th><Th>Last date</Th></Tr>
                  </Thead>
                  <Tbody>
                    {Object.entries(usageStatus.nobita.last_days).map(
                      (domain_and_last_used_date_dict, index) =>
                        <Tr key={index}>
                          <Td><TextWithCopyButton text={domain_and_last_used_date_dict[0]} /></Td><Td>{domain_and_last_used_date_dict[1]}</Td>
                        </Tr>
                    )}
                  </Tbody>
                </Table> : <></>}
              </VStack> :
                <Text>Oops! Something went wrong.</Text>}
          </Stack>
          <Stack shadow="md" bg="white" p={4} spacing={4}>
            <Heading as="h2" size="md" p={4}>
              HENNGE Secure Transfer v1
            </Heading>
            {isLoading ?
              <Center><Skeleton mb={8} width={300} height={220} /></Center> :
              usageStatus ? <VStack> {kumamushiV1GraphData ?
                <Chart options={kumamushiV1GraphData.options} series={kumamushiV1GraphData.series} type="bar" width={330} height={220} />
                : <Text>No data</Text>}
                {usageStatus.kumamushi_v1.last_days ? <Table variant='simple' backgroundColor="white">
                  <Thead>
                    <Tr><Th>Domain</Th><Th>Last date</Th></Tr>
                  </Thead>
                  <Tbody>
                    {Object.entries(usageStatus.kumamushi_v1.last_days).map(
                      (domain_and_last_used_date_dict, index) =>
                        <Tr key={index}>
                          <Td><TextWithCopyButton text={domain_and_last_used_date_dict[0]} /></Td><Td>{domain_and_last_used_date_dict[1]}</Td>
                        </Tr>
                    )}
                  </Tbody>
                </Table> : <></>}
              </VStack> :
                <Text>Oops! Something went wrong.</Text>}
          </Stack>
          <Stack shadow="md" bg="white" p={4} spacing={4}>
            <Heading as="h2" size="md" p={4}>
              HENNGE Secure Transfer v2
            </Heading>
            {isLoading ?
              <Center><Skeleton mb={8} width={300} height={220} /></Center> :
              usageStatus ?
                <VStack> {kumamushiV2GraphData ?
                  <Chart options={kumamushiV2GraphData.options} series={kumamushiV2GraphData.series} type="bar" width={330} height={220} />
                  : <Text>No data</Text>}
                  {usageStatus.kumamushi_v2.last_days ? <Table variant='simple' backgroundColor="white">
                    <Thead>
                      <Tr><Th>Domain</Th><Th>Last date</Th></Tr>
                    </Thead>
                    <Tbody>
                      {Object.entries(usageStatus.kumamushi_v2.last_days).map(
                        (domain_and_last_used_date_dict, index) =>
                          <Tr key={index}>
                            <Td><TextWithCopyButton text={domain_and_last_used_date_dict[0]} /></Td><Td>{domain_and_last_used_date_dict[1]}</Td>
                          </Tr>
                      )}
                    </Tbody>
                  </Table> : <></>}
                </VStack> :
                <Text>Oops! Something went wrong.</Text>}
          </Stack>
        </SimpleGrid>
      </Stack>
    </ChakraProvider>
  )
}
