let backendHost;
export let ENVIRONMENT: string;
const apiVersion = 'v1';

const hostname = window && window.location && window.location.hostname;

if (hostname === 'dfc-dev.log.hennge.one') {
    backendHost = 'https://dfc-dev.log.hennge.one';
    ENVIRONMENT = 'dev';
} else if (hostname === 'daifukucho.tool.hennge.one') {
    backendHost = 'https://daifukucho.tool.hennge.one';
    ENVIRONMENT = 'prod';
} else {
    backendHost = '';
    ENVIRONMENT = 'local';
}

export const API_ROOT = `${backendHost}/api/${apiVersion}`;
export const LOGIN_ENDPOINT = backendHost ? `${API_ROOT}/login` : `http://localhost:8000/api/${apiVersion}/login`;
export const LOGOUT_ENDPOINT = backendHost ? `${API_ROOT}/logout` : `http://localhost:8000/api/${apiVersion}/logout`;
export const SLACK_CHANNEL_URL = "https://hennge.slack.com/archives/C048AE287AM";

export const SERVICE_NAME_MAP = {
    "shobosso": "HAC",
    "fzero": "HED",
    "nobita_v1": "HEA",
    "kumamushi_v1": "HST v1",
    "kumamushi_v2": "HST v2",
    "others": "Others"
}