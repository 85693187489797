import { Box, Button, PlacementWithLogical, Tooltip, useClipboard } from "@chakra-ui/react"

type CopyButtonWithLabelProps = {
    text: string;
    label: string;
    m?: number;
    placement?: PlacementWithLogical;
}
export const CopyButtonWithLabel = ({ text, label, m = 0, placement = "top" }: CopyButtonWithLabelProps) => {

    const { hasCopied, onCopy } = useClipboard(text)
    return (
        <Box m={m}>
            {
                hasCopied ?
                    <Tooltip shouldWrapChildren isOpen hasArrow label="copied!" placement={placement}>
                        <Button size="sm" >{label}</Button>
                    </Tooltip> :
                    <Button size="sm" onClick={onCopy} >{label}</Button>
            }
        </Box>
    )
}