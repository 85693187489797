import {
  ChakraProvider,
  Text,
  Heading,
  Stack,
  HStack,
  Skeleton,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  SimpleGrid,
  Tbody,
  Center,
  Tag,
  Box
} from "@chakra-ui/react"
import { Header } from "./components/Header"
import { useSearchParams } from "react-router-dom";
import { theme } from "./Theme";
import axios from 'axios';
import { useEffect, useState } from "react"
import { API_ROOT } from './assets/const';
import { Customer, DirectoryUsersInfo } from './assets/types';
import { CopyButton } from "./components/CopyButton";
import { RedirectToLogin } from "./assets/utils";

export const DirectoryUsersPage = () => {

  const [searchParams,] = useSearchParams();
  const customer_id = searchParams.get("customer_id");
  const [email, setEmail] = useState<string>("");

  const [customerData, setCustomerData] = useState<Customer | null>(null);
  const [directoryUsers, setDirectoryUsers] = useState<DirectoryUsersInfo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {

    axios.post(`${API_ROOT}/whoami`, {
      withCredentials: true
    })
      .then(response => {
        setLoading(false);
        setEmail(response.data)
        axios.get<Customer>(`${API_ROOT}/customers/${customer_id}`)
          .then((response) => {
            setCustomerData(response.data);
          }).catch(error => {
            console.log(error);
          });
        axios.get(`${API_ROOT}/directory_users/${customer_id}`)
          .then((response) => {
            setDirectoryUsers(response.data)
            setIsLoading(false)
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              setDirectoryUsers({}) // set empty object to show "No data"
            }
            setIsLoading(false)
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          RedirectToLogin();
        }
      });
  }, [customer_id]);


  if (loading) {
    return <>Loading...</>
  }

  return (
    <ChakraProvider theme={theme}>
      <Header user_email={email} />
      <Stack fontSize="md" px={40} py={8} spacing={10} pt={20}>
        <Stack fontSize="md" spacing={4}>
          <HStack>
            <Heading as="h1" size="xl" color="grey.700">{customer_id}</Heading>
            {customer_id ? <CopyButton text={customer_id} /> : <></>}
          </HStack>
          <HStack>
            <Heading size="md" color="grey.700">
              {customerData ? customerData.service_id :
                <Skeleton width={120} height={6} />}
            </Heading>
            {customerData ? <CopyButton text={customerData.service_id} /> : <></>}
          </HStack>
          <HStack>
            <Heading size="md" color="grey.700">
              {customerData ? customerData.company_name_ja :
                <Skeleton width={150} height={6} />}
            </Heading>
            {customerData ? <CopyButton text={customerData.company_name_ja} /> : <></>}
          </HStack>
          <HStack>
            <Heading size="md" color="grey.700">
              {customerData ? customerData.company_name_en :
                <Skeleton width={180} height={6} />}
            </Heading>
            {customerData ? <CopyButton text={customerData.company_name_en} /> : <></>}
          </HStack>
        </Stack>
        <Center><Heading>Directory Users</Heading></Center>
        <SimpleGrid columns={1} spacing={8}>
          <Box shadow="md" bg="white" p={4}>
            {isLoading ?
              <Center><Skeleton mb={8} width={400} height={8} /></Center> :
              directoryUsers ? Object.keys(directoryUsers).length !== 0 ?
                Object.keys(directoryUsers).map((domain, index) => <div key={index} >
                  <Tag size="lg" borderRadius="full" mb={4}>
                    {domain}
                  </Tag>
                  <Table mb={10}>
                    <Thead>
                      <Tr><Th>ID</Th><Th>Addresses</Th></Tr>
                    </Thead>
                    <Tbody>
                      {directoryUsers[domain].map(
                        (directoryUser, index) => <Tr key={index}>
                          <Td>
                            <HStack><Text>{directoryUser.id}</Text><CopyButton text={directoryUser.id} /></HStack>
                          </Td>
                          <Td css={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                            <HStack>
                              <Text>{directoryUser.addresses}</Text><CopyButton text={directoryUser.addresses} />
                            </HStack>
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </div>
                )
                : <Text>No data</Text>
                : <Text>Oops! Something went wrong.</Text>}
          </Box>
        </SimpleGrid>
      </Stack>
    </ChakraProvider>
  )
}
