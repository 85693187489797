import {
    Input,
    InputGroup,
    InputLeftElement,
    Center,
    Stack,
} from "@chakra-ui/react"

import { FormEvent, useState } from "react"
import { AiOutlineSearch } from "react-icons/ai"
import { useNavigate, useSearchParams } from "react-router-dom"


type SearchBarProps = {
    isHome?: boolean;
}
export const SearchBar = ({ isHome=false }: SearchBarProps) => {

    const navigate = useNavigate();
    let [searchQuery, setSearchQuery] = useState('');
    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (searchQuery) {
            navigate(`/search?q=${searchQuery}`);
        }

    };
    const [searchParams, ] = useSearchParams();

    const defaultValue = searchParams.get("q");

    return (<Center>
        <Stack direction="row" spacing={0} shadow={isHome? "md" : undefined}>

            <form id="form-1" onSubmit={handleSubmit}>
                <InputGroup>
                    <InputLeftElement
                        pointerEvents='none'
                        children={
                                <AiOutlineSearch size={20} color='grey' />
                    }
                    />
                    <Input
                        borderColor={isHome?"white":"gray.100"}
                        _hover={{
                            borderColor:"null",
                        }}
                        _focus={isHome?{
                            boxShadow: "0px 0px 30px -10px rgba(0,0,0,0.2);"
                        }:{
                            focusBorderColor:"gray",
                            boxShadow: "0px 0px 20px -10px rgba(0,0,0,0.2);"
                        }}
                        defaultValue={defaultValue == null ? "" : defaultValue}
                        variant="outline"
                        placeholder="Search tenants"
                        size="md"
                        width={isHome?"lg":"sm"}
                        borderRadius={0}
                        bg={isHome?"white":"gray.100"}
                        onChange={event => {
                            setSearchQuery(event.currentTarget.value)
                        }}
                    />
                </InputGroup>

            </form>

        </Stack>
    </Center>
    )
}