import {
    Link,
    Text,
    Flex,
    Avatar,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Stack,
    Center,
    HStack,
    Heading,
    Spacer,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Textarea,
    VStack,
    IconButton,
    chakra,
} from '@chakra-ui/react';

import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchBar } from './SearchBar';
import { API_ROOT, LOGOUT_ENDPOINT, SLACK_CHANNEL_URL } from '../assets/const';
import { FaHeart } from "react-icons/fa"
import { FaPaperPlane } from "react-icons/fa"
import axios from 'axios';
import { RedirectToLogin } from '../assets/utils';
import logo from '../assets/logo.png';

type HeaderProps = {
    user_email: string;
}

export const Header = ({ user_email }: HeaderProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const RedirectToLogout = () => window.location.replace(LOGOUT_ENDPOINT);
    const [isSent, setIsSent] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [slackPostUrl, setSlackPostUrl] = useState<string>("");
    const [input, setInput] = useState("");
    const handleSendClick = () => {
        setIsSending(true)
        axios.post(`${API_ROOT}/whoami`, {
            withCredentials: true
        }).then((response) => {
            axios.post(`${API_ROOT}/send_request`, {
                email: response.data,
                url: window.location.href,
                body: input,
            }).then((response) => {
                setSlackPostUrl(response.data)
                setIsSent(true)
                setIsSending(false)
                setInput("")
            }).catch(error => {
                console.log(error);
            });
        }).catch(error => {
            console.log(error);
            RedirectToLogin();
        });
    };
    const handleInputChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => setInput(e.target.value);
    const handleOnClick = useCallback(() => navigate("/", { replace: false }), [navigate]);
    const isHome = window.location.pathname === "/";

    return (
        <Flex shadow="sm" px={4} h={16} as="header" position="fixed" w="100%" bgColor="white" zIndex={200}>
            <HStack cursor="pointer" onClick={handleOnClick}>
                <chakra.img src={logo} boxSize={10} />
                <Heading size="lg" color="black" >
                    Daifukucho
                </Heading>
            </HStack>
            <Spacer />
            {!isHome ? <SearchBar isHome={false} /> : null}
            <Flex alignItems={'center'}>
                <HStack spacing={4} mr={8}>
                    <IconButton ml={4} aria-label='send feedback' colorScheme="teal" icon={<FaPaperPlane />} onClick={function (_) {
                        setIsSent(false);
                        onOpen();
                    }} _focus={{ boxShadow: "none" }} />

                    <Modal onClose={onClose} isOpen={isOpen} scrollBehavior="inside" size="md" >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>
                                Send Feedback
                            </ModalHeader>
                            <ModalCloseButton _focus={{ boxShadow: "none" }} />
                            <ModalBody>
                                {isSent ?
                                    <VStack>
                                        <Heading size="lg">Thank you</Heading>
                                        <FaHeart color='teal' size={100} />
                                        <Text>Your feedback was successfully submitted to the Daifukucho dev team.</Text>
                                        <Link isExternal href={slackPostUrl} color="teal">See your feedback in the slack channel</Link>
                                    </VStack>
                                    : <Stack>
                                        <Textarea height={200} value={input} onChange={handleInputChange} />
                                        <Text>*Your email address, your feedback for Daifukucho and the URL of the current page will be published in the slack channel <Link isExternal color="teal" href={SLACK_CHANNEL_URL}>#daifukucho-request</Link>.</Text>
                                    </Stack>}
                            </ModalBody>
                            <ModalFooter>
                                {isSent ?
                                    <Button onClick={onClose} _focus={{ boxShadow: "none" }}>Close</Button> :
                                    <Button disabled={!input || isSending} isLoading={isSending} onClick={handleSendClick} colorScheme='teal' _focus={{ boxShadow: "none" }}>Send</Button>
                                }
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    <Menu>
                        <MenuButton
                            _focus={{ boxShadow: "none" }}
                            as={Button}
                            rounded={'full'}
                            variant={'link'}
                            cursor={'pointer'}
                            minW={0}>
                            <Avatar />
                        </MenuButton>
                        <MenuList alignItems={'center'}>
                            <Center>
                                <p>{user_email}</p>
                            </Center>
                            <MenuDivider />
                            <MenuItem onClick={RedirectToLogout}>Logout</MenuItem>
                        </MenuList>
                    </Menu>
                </HStack>
            </Flex>
        </Flex>
    );
}