


import { Box, PlacementWithLogical, Tooltip, useClipboard } from "@chakra-ui/react"
import { FaRegCopy } from "react-icons/fa";

type CopyButtonProps = {
    text: string;
    m?: number;
    placement?: PlacementWithLogical;
}
export const CopyButton = ({ text, m = 0, placement = "top" }: CopyButtonProps) => {

    const { hasCopied, onCopy } = useClipboard(text)
    return (text && text.length > 0 && text !== "-") ? (
        <Box m={m}>
            {
                hasCopied ?
                    <Tooltip shouldWrapChildren isOpen hasArrow label="copied!" placement={placement}>
                        <FaRegCopy color='green' /></Tooltip> :
                    <FaRegCopy cursor="pointer" onClick={onCopy} />
            }
        </Box>
    ) : <></>
}