import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Stack,
  Grid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Spinner,
  Center,
} from "@chakra-ui/react"

import { Header } from "./components/Header"
import { TenantTableRow } from "./components/TenantTableRow"
import { FaAngleRight } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";
import { theme } from "./Theme";
import { useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import axis from 'axios';
import { API_ROOT } from './assets/const';
import { Customer } from './assets/types';
import ReactPaginate from 'react-paginate';
import "./styles/pagination.css"
import { RedirectToLogin } from "./assets/utils";


type ItemsProps = {
  currentItems: Customer[];
}

const Items = ({ currentItems }: ItemsProps) => {
  return (
    <Box bg="white" >
      <Table variant='simple' border="hidden">
        <Thead bg="gray.50">
          <Tr >
            <Th>ID</Th>
            <Th>Tenant</Th>
            <Th>Company</Th>
            <Th>Domains</Th>
            <Th>Connection Date</Th>
            <Th>Platform</Th>
            <Th>Status</Th>
            <Th>Services</Th>
          </Tr>
        </Thead>

        <Tbody>
          {currentItems ?
            Object.values(currentItems).map((object: Customer, index: number) => <TenantTableRow key={index}
              customer_id={object.customer_id}
              service_id={object.service_id}
              company_name={object.company_name_ja}
              domains={object.domains}
              date={object.connection_date}
              status={object.env}
              service_type={object.service_type}
              fzero={object.fzero}
              archive={object.archive}
              hos={object.hos}
              hac={object.hac}
              hcp={object.hcp}
              connect={object.connect}
              saascore={object.saascore}
              tadrill={object.tadrill}
            />
            )
            : <Spinner />}
        </Tbody>
      </Table>
    </Box>
  )

}

type PaginatedItemsProps = {
  itemsPerPage: number;
}

const PaginatedItems = ({ itemsPerPage }: PaginatedItemsProps) => {
  const [searchParams,] = useSearchParams();
  const query = searchParams.get("q");
  const [email, setEmail] = useState<string>("");

  const [customersDataSearchResult, setCustomersDataSearchResult] = useState<any>([]);
  const [customersDataSearchResultLoading, setCustomersDataSearchResultLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  const [currentItems, setCurrentItems] = useState<Customer[]>(Object());
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    axis.post(`${API_ROOT}/whoami`, {
      withCredentials: true
    })
      .then(response => {
        setLoading(false);
        setEmail(response.data)
        console.log(`${API_ROOT}/search?q=${query}`);
        axis.get(`${API_ROOT}/search?q=${query}`, {
          withCredentials: true
        })
          .then(response => {
            setCustomersDataSearchResult(response.data);
            setCustomersDataSearchResultLoading(false);
            console.log(response.data);
          })
          .catch(error => {
            console.log(error);
          });

      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          RedirectToLogin();
        }
      });


  }, [query]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(customersDataSearchResult.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(customersDataSearchResult.length / itemsPerPage));

  }, [itemOffset, itemsPerPage, customersDataSearchResult]);

  if (loading) {
    return <p>loading...</p>
  }

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % customersDataSearchResult.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <ChakraProvider theme={theme}>
      <Header user_email={email} />
      <Box fontSize="lg" mx={10} mb={10} as="main" pt={20}>
        <Grid minH="10vh" p={4}>
          {customersDataSearchResultLoading ?
            <Center><Spinner /></Center> : customersDataSearchResult.length === 0 ?
              <Center>
                <Text>
                  No tenants found
                </Text>
              </Center> :
              <Stack spacing={4} >
                <Text>
                  {customersDataSearchResult.length} tenants found
                </Text>
                <VStack>
                  <ReactPaginate
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    containerClassName="pagination justify-center" // ul(pagination body)
                    pageClassName="page-item" // li
                    pageLinkClassName="page-link" // a
                    activeClassName="active" // active.li
                    activeLinkClassName="active" // active.li < a

                    previousClassName="page-item" // li
                    nextClassName="page-item" // li
                    previousLabel={<FaAngleLeft />} // a
                    previousLinkClassName="previous-link page-item"
                    nextLabel={<FaAngleRight />} // a
                    nextLinkClassName="next-link page-item"

                    disabledClassName="disabled-button d-none"

                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                  />
                  <Items currentItems={currentItems} />

                </VStack>
              </Stack>}

        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export const SearchResult = () => {
  return <PaginatedItems itemsPerPage={10} />

}
