import { ColorModeScript } from "@chakra-ui/react"
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import * as React from "react"
import ReactDOM from "react-dom"
import { Home } from "./Home"
import { SearchResult } from "./SearchResult"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { TenantDetail } from './TenantDetail';
import { ChangeLogs } from './ChangeLogs';
import { UsageStatus } from './UsageStatus';
import { HSTDiffsTable } from "./HSTDiffsTable";
import { HST2DiffsTable } from "./HST2DiffsTable";
import { BlackList } from "./BlackList";
import { DirectoryGroupsPage } from "./DirectoryGroupsPage";
import { DirectoryUsersPage } from "./DirectoryUsersPage";

ReactDOM.render(
  <BrowserRouter>

    <React.StrictMode>
      <ColorModeScript />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/view_detail" element={<TenantDetail/>} />
        <Route path="/change_logs" element={<ChangeLogs/>} />
        <Route path="/usage_status" element={<UsageStatus/>} />
        <Route path="/black_list" element={<BlackList/>} />
        <Route path="/directory_groups" element={<DirectoryGroupsPage/>} />
        <Route path="/directory_users" element={<DirectoryUsersPage/>} />
        <Route path="/search" element={<SearchResult />} />
        <Route path="/hst_diffs" element={<HSTDiffsTable />} />
        <Route path="/hst2_diffs" element={<HST2DiffsTable />} />
      </Routes>

    </React.StrictMode>

  </BrowserRouter>,
  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
