import {
  ChakraProvider,
  VStack,
  Heading,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Code,
} from "@chakra-ui/react"

import SyntaxHighlighter from 'react-syntax-highlighter';
import { Header } from "./components/Header"
import { useSearchParams } from "react-router-dom";
import { theme } from "./Theme";
import axios from 'axios';
import { useEffect, useState } from "react"
import { API_ROOT } from './assets/const';
import { Customer } from './assets/types';
import { RedirectToLogin } from "./assets/utils";

export const ChangeLogs = () => {

  const [searchParams,] = useSearchParams();
  const customer_id = searchParams.get("customer_id");
  const [email, setEmail] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [customerData, setCustomerData] = useState<Customer | null>(null);
  // const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {

    axios.post(`${API_ROOT}/whoami`, {
      withCredentials: true
    })
      .then(response => {
        // setLoading(false);
        setEmail(response.data)
        axios.get<Customer>(`${API_ROOT}/customers/${customer_id}`)
          .then((response) => {
            setCustomerData(response.data);
          }).catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          RedirectToLogin();
        }
      });


  }, [customer_id]);

  const data = `{
  "admin_email": "xxx@xxx.co.jp,xxx@xxx.co.jp,xxx@xxx.co.jp,xxx@xxx.co.jp,xxx@xxx.co.jp",
  "kumamushi_domain": "xxxx.co.jp",
  "max_upload_size": "2000",
  "parent_domain": "xxx.co.jp",
  "scheme": "o365",
  "total_accounts": "200"
}`

  return (
    <ChakraProvider theme={theme}>
      <Header user_email={email} />
      <Stack fontSize="md" px={40} py={8} spacing={10} pt={20}>
        <Stack fontSize="md" spacing={4}>
          <Heading as="h1" size="xl" color="grey.700">{customer_id}</Heading>
          <Heading size="md" color="grey.700">{customerData ? customerData.company_name_ja : <Skeleton width={150} height={6} />}</Heading>
          <Heading size="md" color="grey.700">{customerData ? customerData.company_name_en : <Skeleton width={180} height={6} />}</Heading>

        </Stack>
        <VStack spacing={4}>
          <Heading size="md" color="grey.700">Change Logs</Heading>

          <Table variant='simple' backgroundColor="white">
            <Thead backgroundColor="gray" >
              <Tr>
                <Th color="white">Date</Th>
                <Th color="white">Slack Link</Th>
                <Th color="white">Type</Th>
                <Th color="white">Operation [Domain]</Th>
                <Th color="white">Command</Th>
                <Th color="white">Result</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>2021-12-22 16:12</Td>
                <Td>#one-garden</Td>
                <Td>services - kumamushi</Td>
                <Td>追加 [xxxx.co.jp]</Td>
                <Td>/domains</Td>
                <Td>{<Code p={1} cursor="pointer" onClick={onOpen}>{"{\"fzero_domains\": \"xxxx.co.jp\"}".slice(0, 5) + "..."}</Code>}</Td>
              </Tr>
              <Tr>
                <Td>2021-12-22 16:12</Td>
                <Td>#one-garden</Td>
                <Td>services - kumamushi</Td>
                <Td>追加 [xxxx.co.jp]</Td>
                <Td>/domains</Td>
                <Td>{<Code p={1} cursor="pointer" onClick={onOpen}>{"{\"fzero_domains\": \"xxxx.co.jp\"}".slice(0, 5) + "..."}</Code>}</Td>
              </Tr><Tr>
                <Td>2021-12-22 16:12</Td>
                <Td>#one-garden</Td>
                <Td>services - kumamushi</Td>
                <Td>追加 [xxxx.co.jp]</Td>
                <Td>/domains</Td>
                <Td>{<Code p={1} cursor="pointer" onClick={onOpen}>{"{\"fzero_domains\": \"xxxx.co.jp\"}".slice(0, 5) + "..."}</Code>}</Td>
                <Modal
                  onClose={onClose}
                  isOpen={isOpen}
                  scrollBehavior="inside"
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Result</ModalHeader>
                    <ModalCloseButton _focus={{ boxShadow: "none" }} />
                    <ModalBody>
                      <SyntaxHighlighter language="javascript" >
                        {data}
                      </SyntaxHighlighter>
                    </ModalBody>
                    <ModalFooter>
                      <Button onClick={onClose} _focus={{ boxShadow: "none" }}>Close</Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </Tr>
            </Tbody>
          </Table>
        </VStack>

      </Stack>
    </ChakraProvider>
  )
}
