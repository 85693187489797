import {
    Table,
    Tr,
    Td,
    Tbody,
} from "@chakra-ui/react"
import { KumamushiV1Object } from './assets/types';
var CryptoJS = require("crypto-js");

export const HSTDiffsTable = () => {
    const kumamushiV1TableKeys: Array<string> = CryptoJS.AES.decrypt(localStorage.getItem("kumamushiV1TableKeys") || "", "diffs").toString(CryptoJS.enc.Utf8).split(",");
    const kumamushiV1TableObjects: Array<KumamushiV1Object> = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("kumamushiV1TableObjects") || "", "diffs").toString(CryptoJS.enc.Utf8));
    return <Table variant='simple'>
        <Tbody>
            {Array.from(kumamushiV1TableKeys).map((key, number) =>
                <Tr key={number}>
                    <Td backgroundColor="grey" color="white">{key}</Td>
                    {Object.values(kumamushiV1TableObjects).map((object: KumamushiV1Object, index: number) =>
                        <Td key={index} backgroundColor={object[key] === kumamushiV1TableObjects[0][key] ? "white" : "pink"} css={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                            {object[key] === undefined ? "-" : String(object[key])}
                        </Td>)}
                </Tr>
            )}
        </Tbody>
    </Table>
}