import {
    chakra,
    Box,
    Text,
    Link,
    Stack,
    Tr,
    Td,
    HStack,
    Tag,
    Badge,
} from "@chakra-ui/react"

import o365 from "../assets/o365.svg"
import google from "../assets/google.svg"
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { CopyButton } from "./CopyButton";
import { TextModal } from "./TextModal";

type ConfigRowProps = {
    header: string;
    value: string;
}

export const ConfigRow = ({ header, value }: ConfigRowProps) => {

    function capitalizeFirstLetter(text: string) {
        return text.charAt(0).toUpperCase() + text.toLocaleLowerCase().slice(1);
    }
    var valueComponent;
    if (header === "More details") {
        valueComponent = <TextModal text={value} title={"Details"} />
    } else if (value.includes("Disable")) {
        valueComponent = <Tag>{value}</Tag>
    } else if (value.includes("Production")) {
        valueComponent = <Tag variant='solid' colorScheme='orange'>{value}</Tag>
    } else if (value.includes("Office365")) {
        valueComponent = <Stack direction="row">
            <Box boxSize={4}>
                <chakra.img src={o365} />
            </Box>
            <Text>{value}</Text>
        </Stack>
    } else if (value === "google") {
        valueComponent = <Stack direction="row">
            <Box boxSize={4}>
                <chakra.img src={google} />
            </Box>
            <Text>{value}</Text>
        </Stack>
    } else if (value.includes("OtherServices")) {
        valueComponent = <Badge colorScheme='orange'>{value}</Badge>
    } else if (value.toLowerCase() === "true") {
        valueComponent = <Tag variant='solid' colorScheme='green'>{capitalizeFirstLetter(value)}</Tag>
    } else if (value.toLowerCase() === "false") {
        valueComponent = <Tag variant='solid' colorScheme='red'>{capitalizeFirstLetter(value)}</Tag>
    } else if (value.toLowerCase() === "active") {
        valueComponent = <Tag variant='solid' colorScheme='yellow'>{capitalizeFirstLetter(value)}</Tag>
    } else if (value.toLowerCase() === "inactive") {
        valueComponent = <Tag variant='solid' colorScheme='gray'>{capitalizeFirstLetter(value)}</Tag>
    } else if (["External Admin URL", "Admin URL", "HAC support viewer"].includes(header)) {
        valueComponent = <HStack>
            <Link href={value} isExternal>
                <HStack><Text color="blue">{value}</Text><ExternalLinkIcon mx='2px' /></HStack>
            </Link>
            <CopyButton text={value} />
        </HStack>
    } else if (header === "Backup Period" &&
        ["10 years", "19 years", "99 years"].includes(value.trim())) {
        valueComponent = <HStack><Text>{value}</Text><Tag variant='solid' colorScheme='red'>永年</Tag><CopyButton text={value} /></HStack>
    } else if (value !== "-" && value) {
        valueComponent = <HStack><Text>{value}</Text><CopyButton text={value} /></HStack>
    }
    else {
        valueComponent = <Text>-</Text>
    }
    return (
        <Tr>
            <Td textAlign="right" style={{ verticalAlign: "top" }} width={200}>
                <Text color="gray">
                    {header}
                </Text>
            </Td>
            <Td css={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }} >
                <Text backgroundColor={(header === "Remark" && value !== "-") ? "red.100" : "white"}>
                    {valueComponent}
                </Text>
            </Td>

        </Tr>
    )
}