import { LOGIN_ENDPOINT } from "./const";

export const getApexChartsOptions = (id: string, title: string, colors: Array<string>, categories: Array<string>) => {
  return {
    title: {
      text: title,
      align: 'center',
    },
    colors: colors,
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    chart: {
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
      id: id,
      stacked: true,
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        format: "dd.MM.yyyy"
      }
    },
    axisBorder: {
      show: true,
    },
    axisTicks: {
      show: true,
    },
    labels: categories,
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
        format: 'd MMM',
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false
    }
  }
}

export const RedirectToLogin = () => {
  window.location.replace(LOGIN_ENDPOINT + "?next=" + encodeURI(window.location.href));
}