import { ConfigRow } from './ConfigRow';
import {
  Box,
  Heading,
  Stack,
  Tag,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
  Text,
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  TableCaption,
  TableContainer,
  HStack,
  Button,
  Progress,
} from "@chakra-ui/react"
import { DossunJobs, KumamushiV1Object, KumamushiV2Object, Services } from '../assets/types';
import { useCallback, useState } from "react"
import { TextModal } from "./TextModal";
import { API_ROOT } from '../assets/const';
import axios from 'axios';
import { RedirectToLogin } from '../assets/utils';
import { DossunButton } from './DossunButton';
var CryptoJS = require("crypto-js");

type TenantDetailCardProps = {
  title: string;
  servicesData: Services | null;
  serviceName: string;
  customer_id: string;
  loadingStatus: string;
  dossunJobs: DossunJobs | null;
}

export const TenantDetailCard = ({ title, servicesData, serviceName, customer_id, loadingStatus, dossunJobs }: TenantDetailCardProps) => {

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  const handleHstV1OpenInNewTabClick = useCallback(() => {
    openInNewTab('/hst_diffs')
  }, []);
  const handleHstV2OpenInNewTabClick = useCallback(() => {
    openInNewTab('/hst2_diffs')
  }, []);
  const data = servicesData ? (servicesData[serviceName as keyof Services] as object[] | null) : null;
  const kumamushiV1TableKeys = servicesData && servicesData.kumamushi_v1_diff_table_keys ? servicesData.kumamushi_v1_diff_table_keys : []
  const kumamushiV1TableObjects = servicesData && servicesData.kumamushi_v1_diff_table ? servicesData.kumamushi_v1_diff_table : []
  if (serviceName === "kumamushi_v1") {
    localStorage.setItem("kumamushiV1TableKeys", CryptoJS.AES.encrypt(Array.from(kumamushiV1TableKeys).toString(), "diffs").toString());
    localStorage.setItem("kumamushiV1TableObjects", CryptoJS.AES.encrypt(JSON.stringify(kumamushiV1TableObjects), "diffs").toString());
  }
  type KumamushiV2AdminMapType = {
    [key: string]: string[][]
  };
  const [kumamushiV2AdminMap, setKumamushiV2AdminMap] = useState<KumamushiV2AdminMapType>({});
  const [kumamushiV2AdminLoadingMap, setKumamushiV2AdminLoadingMap] = useState<{ [key: string]: boolean }>({});
  const [kumamushiV2AdminErrorMap, setKumamushiV2AdminErrorMap] = useState<{ [key: string]: boolean }>({});
  const fetchKumamushiV2AdminData = async (domain_name: string) => {
    setKumamushiV2AdminLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      [domain_name]: true,
    }));
    setKumamushiV2AdminErrorMap((prevErrorMap) => ({
      ...prevErrorMap,
      [domain_name]: false,
    }));

    axios.post(`${API_ROOT}/whoami`, {
      withCredentials: true
    }).then(() => {
      axios.get(`${API_ROOT}/kumamushi_v2_admin_info/${customer_id}/${domain_name}`).then((response) => {
        setKumamushiV2AdminMap((prevDataMap) => ({
          ...prevDataMap,
          [domain_name]: response.data,
        }));
        setKumamushiV2AdminLoadingMap((prevLoadingMap) => ({
          ...prevLoadingMap,
          [domain_name]: false,
        }));
        setKumamushiV2AdminErrorMap((prevErrorMap) => ({
          ...prevErrorMap,
          [domain_name]: false,
        }));
      }).catch(error => {
        if (error.response.status === 404) {
          setKumamushiV2AdminMap((prevDataMap) => ({
            ...prevDataMap,
            [domain_name]: [],
          }));
          setKumamushiV2AdminErrorMap((prevErrorMap) => ({
            ...prevErrorMap,
            [domain_name]: false,
          }));
        } else {
          console.log(error);
          setKumamushiV2AdminErrorMap((prevErrorMap) => ({
            ...prevErrorMap,
            [domain_name]: true,
          }));
        }
        setKumamushiV2AdminLoadingMap((prevLoadingMap) => ({
          ...prevLoadingMap,
          [domain_name]: false,
        }));
      });
    }).catch(error => {
      console.log(error);
      RedirectToLogin();
    });
  };
  const kumamushiV2AdminsInfo = servicesData && servicesData.kumamushi_v2_admins_info ? servicesData.kumamushi_v2_admins_info : []
  const kumamushiV2TableKeys = servicesData && servicesData.kumamushi_v2_diff_table_keys ? servicesData.kumamushi_v2_diff_table_keys : []
  const kumamushiV2TableObjects = servicesData && servicesData.kumamushi_v2_diff_table ? servicesData.kumamushi_v2_diff_table : []
  if (serviceName === "kumamushi_v2") {
    localStorage.setItem("kumamushiV2TableKeys", CryptoJS.AES.encrypt(Array.from(kumamushiV2TableKeys).toString(), "diffs").toString());
    localStorage.setItem("kumamushiV2TableObjects", CryptoJS.AES.encrypt(JSON.stringify(kumamushiV2TableObjects), "diffs").toString());
  }
  const handleBlackListClick = useCallback(() => {
    openInNewTab('/black_list?customer_id=' + customer_id)
  }, [customer_id]);

  const handleDirectoryGroupsClick = useCallback(() => {
    openInNewTab('/directory_groups?customer_id=' + customer_id)
  }, [customer_id]);

  const handleDirectoryUsersClick = useCallback(() => {
    openInNewTab('/directory_users?customer_id=' + customer_id)
  }, [customer_id]);
  
  return <Accordion id={serviceName} defaultIndex={[0]} allowMultiple>
    <AccordionItem shadow="md" bg="white">
      <h2>
        <Stack direction="row">
          <HStack>
            <Heading as="h2" size="md" p={4}>
              {title}
            </Heading>
            <DossunButton customer_id={customer_id} dossunJobs={dossunJobs} serviceName={serviceName} />
            {serviceName === "fzero" && <Button colorScheme='blue' variant='outline' size="xs" _focus={{ boxShadow: "none" }} onClick={handleDirectoryGroupsClick} >
              Directory Groups
            </Button>}
            {serviceName === "fzero" && <Button colorScheme='cyan' variant='outline' size="xs" _focus={{ boxShadow: "none" }} onClick={handleDirectoryUsersClick} >
              Directory Users
            </Button>}
            {serviceName === "fzero" && <Button colorScheme='black' variant='outline' size="xs" _focus={{ boxShadow: "none" }} onClick={handleBlackListClick} >
              Black List
            </Button>}
          </HStack>
          <Spacer />
          <AccordionButton width="100" _focus={{ boxShadow: "none" }}>
            <AccordionIcon />
          </AccordionButton>
        </Stack>
      </h2>
      <AccordionPanel pb={6}>
        <Stack>
          {servicesData && serviceName === "kumamushi_v1" ?
            <Accordion allowToggle>
              <AccordionItem >
                <AccordionButton width="50" _focus={{ boxShadow: "none" }}>
                  <HStack direction="row">
                    <AccordionIcon />
                    <Text>Diffs</Text>
                  </HStack>
                </AccordionButton>
                <AccordionPanel pb={6}>
                  {serviceName === "kumamushi_v1" && data ?
                    <Stack>
                      <Button width={200} onClick={handleHstV1OpenInNewTabClick}>Open in New Tab</Button>
                      <TableContainer>
                        <Table variant='simple'>
                          <Tbody>
                            {Array.from(kumamushiV1TableKeys).map((key, number) => <Tr key={number}>
                              <Td backgroundColor="grey" color="white">{key}</Td>
                              {Object.values(kumamushiV1TableObjects).map((object: KumamushiV1Object, index: number) =>
                                <Td key={index} backgroundColor={object[key] === kumamushiV1TableObjects[0][key] ? "white" : "pink"} css={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                                  {object[key] === undefined ? "-" : String(object[key])}
                                </Td>)}
                            </Tr>
                            )}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Stack>
                    : <Text>There is no tenant</Text>}
                </AccordionPanel>
              </AccordionItem>
            </Accordion> : <></>}

          {loadingStatus === "loading" ? <Progress size='xs' isIndeterminate colorScheme="teal" /> : loadingStatus === "loaded" ?
            data ?
              data.map((domain_info_dict, index) => <Box key={index}>
                {data.length === 1 ? <></> : <Tag size="lg" borderRadius="full" mb={4}>
                  {Object.entries(domain_info_dict)[0][1]}
                </Tag>}
                <Stack>
                  <Table variant='unstyled' mb={8}>
                    <Tbody>
                      {
                        Object.entries(data[index]).map((domain_info_item, index) => <ConfigRow header={domain_info_item[0]} value={domain_info_item[1]} key={index} />)
                      }
                    </Tbody>
                  </Table>
                </Stack>
              </Box>
              ) : <Text>There is no info</Text>
            : <Text>Oops! Something went wrong.</Text>
          }
          {servicesData && serviceName === "kumamushi_v2" ?
            <Stack spacing={8}>
              <AdminTable tableContent={kumamushiV2AdminsInfo} />
              <Heading size='sm'>Domains</Heading>
              <Accordion allowToggle>
                <AccordionItem >
                  <AccordionButton width="50" _focus={{ boxShadow: "none" }}>
                    <HStack direction="row">
                      <AccordionIcon />
                      <Text>Diffs</Text>
                    </HStack>
                  </AccordionButton>
                  <AccordionPanel pb={6}>
                    {data ?
                      <Stack>
                        <Button width={200} onClick={handleHstV2OpenInNewTabClick}>Open in New Tab</Button>
                        <TableContainer>
                          <Table variant='simple'>
                            <Tbody>
                              {Array.from(kumamushiV2TableKeys).map((key, number) => <Tr key={number}>
                                <Td backgroundColor="grey" color="white">{key}</Td>
                                {Object.values(kumamushiV2TableObjects).map((object: KumamushiV2Object, index: number) =>
                                  <Td key={index} backgroundColor="white" css={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                                    {object[key] === undefined ? "-" : String(object[key])}
                                  </Td>)
                                }
                              </Tr>
                              )}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </Stack>
                      : <Text>There is no tenant</Text>}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              {
                servicesData.kumamushi_v2_domains_info && Object.entries(servicesData.kumamushi_v2_domains_info).map((domain_info_item, index) => {
                  const domain_name = domain_info_item[0];
                  const domain_info = domain_info_item[1].toString();
                  return <Box key={index}>
                    <Tag size="lg" borderRadius="full" mb={4}>
                      {domain_name}
                    </Tag>
                    <TextModal text={domain_info} title={"Kumamushi v2 domain detail settings"} />
                    <Box height={4} />
                    {kumamushiV2AdminLoadingMap[domain_name as keyof object]
                      ? <Progress size='xs' isIndeterminate colorScheme="teal" />
                      : kumamushiV2AdminErrorMap[domain_name as keyof object]
                        ? <Text>Oops! Something went wrong.</Text>
                        : kumamushiV2AdminMap[domain_name as keyof object]
                          ? <Stack>
                            <AdminTable tableContent={kumamushiV2AdminMap[domain_name as keyof object]} />
                          </Stack>
                          : <Button flexShrink={0} colorScheme='black' variant='outline' size="xs" _focus={{ boxShadow: "none" }} onClick={() => fetchKumamushiV2AdminData(domain_name)} >
                            Get admin info
                          </Button>}
                  </Box>
                })
              }
            </Stack> : <></>}
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
}

const AdminTable = (props: { tableContent: string[][] }) => {
  if (props.tableContent.length === 0) {
    return <Text>No admin users</Text>
  } else {
    return <TableContainer>
      <TableCaption>Admin users</TableCaption>
      <Table variant='simple'>
        <Thead>
          <Tr><Th>Email</Th><Th>Admin type</Th><Th>Created at</Th><Th>Last logged in at</Th><Th>is fzero admin</Th></Tr>
        </Thead>
        <Tbody>
          {props.tableContent.map((item, index) => <Tr key={index}>
            <Td>{item[0]}</Td>
            <Td>{item[1]}</Td>
            <Td>{item[2]}</Td>
            <Td>{item[3]}</Td>
            <Td>{item[4]}</Td>
          </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  }
};