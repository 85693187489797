import {
  ChakraProvider,
  Text,
  Heading,
  Stack,
  HStack,
  Skeleton,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  SimpleGrid,
  Tbody,
  Center,
  Tag,
  Box,
  TableContainer
} from "@chakra-ui/react"
import { Header } from "./components/Header"
import { useSearchParams } from "react-router-dom";
import { theme } from "./Theme";
import axios from 'axios';
import { useEffect, useState } from "react"
import { API_ROOT } from './assets/const';
import { BlackListInfo, Customer } from './assets/types';
import { CopyButton } from "./components/CopyButton";
import { RedirectToLogin } from "./assets/utils";

export const BlackList = () => {

  const [searchParams,] = useSearchParams();
  const customer_id = searchParams.get("customer_id");
  const [email, setEmail] = useState<string>("");

  const [customerData, setCustomerData] = useState<Customer | null>(null);
  const [blackList, setBlackList] = useState<BlackListInfo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {

    axios.post(`${API_ROOT}/whoami`, {
      withCredentials: true
    })
      .then(response => {
        setLoading(false);
        setEmail(response.data)
        axios.get<Customer>(`${API_ROOT}/customers/${customer_id}`)
          .then((response) => {
            setCustomerData(response.data);
          }).catch(error => {
            console.log(error);
          });
        axios.get(`${API_ROOT}/black_list/${customer_id}`)
          .then((response) => {
            setBlackList(response.data)
            setIsLoading(false)
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              setBlackList(
                {"senders": {}, "tenants": []}
              ) // set empty object to show "No data"
            }
            setIsLoading(false)
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          RedirectToLogin();
        }
      });
  }, [customer_id]);


  if (loading) {
    return <>Loading...</>
  }

  return (
    <ChakraProvider theme={theme}>
      <Header user_email={email} />
      <Stack fontSize="md" px={40} py={8} spacing={10} pt={20}>
        <Stack fontSize="md" spacing={4}>
          <HStack>
            <Heading as="h1" size="xl" color="grey.700">{customer_id}</Heading>
            {customer_id ? <CopyButton text={customer_id} /> : <></>}
          </HStack>
          <HStack>
            <Heading size="md" color="grey.700">
              {customerData ? customerData.service_id :
                <Skeleton width={120} height={6} />}
            </Heading>
            {customerData ? <CopyButton text={customerData.service_id} /> : <></>}
          </HStack>
          <HStack>
            <Heading size="md" color="grey.700">
              {customerData ? customerData.company_name_ja :
                <Skeleton width={150} height={6} />}
            </Heading>
            {customerData ? <CopyButton text={customerData.company_name_ja} /> : <></>}
          </HStack>
          <HStack>
            <Heading size="md" color="grey.700">
              {customerData ? customerData.company_name_en :
                <Skeleton width={180} height={6} />}
            </Heading>
            {customerData ? <CopyButton text={customerData.company_name_en} /> : <></>}
          </HStack>
        </Stack>
        <Center><Heading>Black List</Heading></Center>
        <SimpleGrid columns={1} spacing={8}>
          <Box shadow="md" bg="white" p={4}>
            <Heading as="h2" size="md" p={4}>
              Senders
            </Heading>
            {isLoading ?
              <Center><Skeleton mb={8} width={400} height={8} /></Center> :
              blackList ? Object.keys(blackList.senders).length !== 0 ?
                Object.keys(blackList.senders).map((domain, index) => <div key={index} >
                  <Tag size="lg" borderRadius="full" mb={4}>
                    {domain}
                  </Tag>
                  <TableContainer>
                    <Table mb={10}>
                      <Thead>
                        <Tr><Th>Entity</Th><Th>Reason</Th><Th>Reject mode</Th><Th>Start time</Th><Th>Can delist</Th></Tr>
                      </Thead>
                      <Tbody>
                        {blackList.senders[domain].map(
                          (sender, index) => <Tr key={index}>
                            <Td>{sender.entity}</Td>
                            <Td><Center>{sender.reason}</Center></Td>
                            <Td><Center>{sender.reject_mode}</Center></Td>
                            <Td><Center>{sender.start_time}</Center></Td>
                            <Td><Center>{sender.can_delist === true ? "True" : "False"}</Center></Td>
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </div>
                )
                : <Text>No data</Text>
                : <Text>Oops! Something went wrong.</Text>}
          </Box>
          <Box shadow="md" bg="white" p={4}>
            <Heading as="h2" size="md" p={4}>
              Tenants
            </Heading>
            {isLoading ?
              <Center><Skeleton mb={8} width={400} height={8} /></Center> :
              blackList ? blackList.tenants.length ?
                <Table>
                  <Thead>
                    <Tr><Th>Entity</Th><Th>Reject mode</Th><Th>End time</Th></Tr>
                  </Thead>
                  <Tbody>
                    {blackList.tenants.map(
                      (tenant, index) => <Tr key={index}>
                        <Td>{tenant.entity}</Td>
                        <Td>{tenant.reject_mode}</Td>
                        <Td>{tenant.end_time}</Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
                : <Text>No data</Text>
                : <Text>Oops! Something went wrong.</Text>}
          </Box>
        </SimpleGrid>
      </Stack>
    </ChakraProvider>
  )
}
