

import {
    Text,
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer,
    HStack,
    Button,
    IconButton,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Link,
    Spinner,
    Center,
    VStack,
    useDisclosure,
} from "@chakra-ui/react"
import { DossunJobInfo, DossunJobs } from '../assets/types';
import DossunLogo from '../assets/dossun.png';
import { useState } from "react"
import { API_ROOT, ENVIRONMENT } from '../assets/const';
import axios from 'axios';
import { RedirectToLogin } from '../assets/utils';
import { FaPlay } from "react-icons/fa"
import { ExternalLinkIcon } from "@chakra-ui/icons";


type DossunButtonProps = {
    customer_id: string;
    dossunJobs: DossunJobs | null;
    serviceName?: string | null;
}

export const DossunButton = ({ customer_id, dossunJobs, serviceName = null }: DossunButtonProps) => {
    const [isSent, setIsSent] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    const { isOpen: isOpenDossun, onOpen: onOpenDossun, onClose: onCloseDossun } = useDisclosure()
    const [slackPostUrl, setSlackPostUrl] = useState<string>("");
    const handleStartDossunJobButtonClick = ({ jobName, customer_id }: { jobName: string, customer_id: string }) => {
        setIsSending(true)
        axios.post(`${API_ROOT}/whoami`, {
            withCredentials: true
        }).then(() => {
            axios.post(`${API_ROOT}/dossun_slack`, {
                customer_id: customer_id,
                job_name: jobName,
            }).then((response) => {
                setSlackPostUrl(response.data.slack_link)
                setIsSent(true)
                setIsSending(false)
            }).catch(error => {
                console.log(error);
            });
        }).catch(error => {
            console.log(error);
            RedirectToLogin();
        });
    };
    if (dossunJobs === null) {
        return <Spinner />
    }

    return <HStack>
        <IconButton
            _focus={{ boxShadow: "none" }}
            aria-label="Dossun Button"
            icon={<Image src={DossunLogo} alt="Dossun Button" boxSize="24px" />}
            onClick={
                () => {
                    onOpenDossun();
                    setIsSent(false);
                }
            }
        />
        <Modal size="xl" onClose={onCloseDossun} isOpen={isOpenDossun} scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Dossun Jobs
                </ModalHeader>
                <ModalCloseButton _focus={{ boxShadow: "none" }} />
                <ModalBody>
                    {isSent ? <Link href={slackPostUrl} isExternal>
                        <HStack>
                            <Text color="teal">Continue the job in Slack</Text>
                            <ExternalLinkIcon mx='2px' />
                        </HStack>
                    </Link>
                        : isSending
                            ? <Center><Spinner /></Center>
                            : serviceName ? <DossunJobTable customer_id={customer_id} dossunJobInfo={dossunJobs[serviceName as keyof DossunJobs]} handleStartDossunJobButtonClick={handleStartDossunJobButtonClick} /> :
                                <VStack>
                                    <DossunJobTable customer_id={customer_id} dossunJobInfo={["others", "shobosso", "fzero", "nobita_v1", "kumamushi_v1", "kumamushi_v2", "saascore", "tadrill"].map(key => dossunJobs[key as keyof DossunJobs]).flat()} handleStartDossunJobButtonClick={handleStartDossunJobButtonClick} />
                                </VStack>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onCloseDossun} _focus={{ boxShadow: "none" }}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </HStack>
}




type DossunJobTableProps = {
    customer_id: string;
    dossunJobInfo: DossunJobInfo[];
    handleStartDossunJobButtonClick: ({ jobName, customer_id }: { jobName: string, customer_id: string }) => void;
}
const DossunJobTable = ({ customer_id, dossunJobInfo, handleStartDossunJobButtonClick }: DossunJobTableProps) => {
    return <TableContainer>
        <Table variant='simple'>
            <Tbody>
                {dossunJobInfo.map((item, index) => <Tr key={index}>
                    <Td css={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                        <VStack><Text>{item.display_name}</Text>
                            <Text color="gray.500" fontSize="sm"> {item.description}</Text>
                        </VStack>
                    </Td>
                    <Td>
                        <Center>
                            <IconButton
                                ml={4}
                                aria-label='start dossun job'
                                colorScheme="teal"
                                icon={<FaPlay />}
                                onClick={function (_) {
                                    handleStartDossunJobButtonClick({ jobName: item.name, customer_id: customer_id });
                                }}
                                _focus={{ boxShadow: "none" }}
                            />
                        </Center>
                    </Td>
                </Tr>
                )}
            </Tbody>
        </Table>
    </TableContainer>
}