


import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    HStack,
    Text,
    Button,
} from "@chakra-ui/react"
import { CopyButton } from "./CopyButton";
import SyntaxHighlighter from "react-syntax-highlighter";


type TextModalProps = {
    title: string;
    text: string;
    show_length?: number;
}
export const TextModal = ({ title, text, show_length = 100 }: TextModalProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    if (text.length < show_length) {
        if (text === "-") {
            return <Text>{text}</Text>
        }
        else {
            return (
                <SyntaxHighlighter language="javascript">
                    {text}
                </SyntaxHighlighter>
            )
        }
    }
    return (<Box cursor="pointer">

        <SyntaxHighlighter language="javascript" onClick={onOpen}>
            {text.slice(0, show_length) + " ..."}
        </SyntaxHighlighter>
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            scrollBehavior="inside"
            size="3xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader><HStack><Text>{title}</Text><CopyButton text={text} /></HStack></ModalHeader>
                <ModalCloseButton _focus={{ boxShadow: "none" }} />
                <ModalBody>
                    <SyntaxHighlighter language="javascript" >
                        {text}
                    </SyntaxHighlighter>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose} _focus={{ boxShadow: "none" }}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Box>

    )

}