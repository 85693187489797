import React, { useCallback } from 'react';
import {
    chakra,
    Box,
    Center,
    Badge,
    Link,
    Tr,
    Td,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Button,
    useDisclosure,
    HStack,
    Text,
    VStack,
} from "@chakra-ui/react"

import o365 from "../assets/o365.svg"
import google from "../assets/google.svg"
import SyntaxHighlighter from 'react-syntax-highlighter';
import { CopyButton } from './CopyButton';
import { ServiceBadge } from './ServiceBadge';

type TenantTableRowProps = {
    service_id: string;
    customer_id: string;
    company_name: string;
    domains: string;
    date: string;
    service_type: string;
    status: string;
    fzero: string;
    archive: string;
    hos: string;
    hac: string;
    hcp: string;
    connect: string;
    saascore: string;
    tadrill: string;
}


export const TenantTableRow: React.FC<TenantTableRowProps> = ({
    service_id, customer_id, company_name, domains, date, status, service_type,
    fzero, archive, hos, hac, hcp, connect, saascore, tadrill
}: TenantTableRowProps) => {
    const openInNewTab = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    const handleOnClick = useCallback(() => {
        openInNewTab('/view_detail?customer_id=' + customer_id,);
    }, [customer_id]);
    const { isOpen, onOpen, onClose } = useDisclosure()

    var serviceType;
    if (service_type === "GoogleApps") {
        serviceType = <chakra.img src={google} />;
    } else if (service_type === "Office365") {
        serviceType = <chakra.img src={o365} />
    } else {
        serviceType = <Badge colorScheme="orange">Other</Badge>
    }
    return (
        <Tr  >
            <Td> {service_id} </Td>
            <Td> <Link onClick={handleOnClick} color="green">{customer_id}</Link></Td>
            <Td>{company_name}</Td>
            <Td>{domains ? <Center><Link color="green" p={1} cursor="pointer" onClick={onOpen}>{domains.split(" ").length}</Link></Center> : <></>}</Td>
            {domains ? <Modal
                onClose={onClose}
                isOpen={isOpen}
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader><HStack><Text>Domains</Text><CopyButton text={domains} /></HStack></ModalHeader>
                    <ModalCloseButton _focus={{ boxShadow: "none" }} />
                    <ModalBody>
                        <SyntaxHighlighter language="javascript" >
                            {domains.split(' ').join("\n")}
                        </SyntaxHighlighter>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose} _focus={{ boxShadow: "none" }}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal> : <></>}
            <Td><Center>{date}</Center></Td>
            <Td>
                <Center>
                    <Box boxSize={6}>
                        {serviceType}
                    </Box>
                </Center>
            </Td>
            <Td>
                <Center>
                    {status === "Production" ? <Badge ml='1' colorScheme="green" rounded="lg">
                        {status}
                    </Badge> : status}</Center></Td>
            <Td >
                <VStack spacing={0.5}>
                    <HStack>
                        <ServiceBadge serviceName="HAC" status={hac} placement="top" />
                        <ServiceBadge serviceName="DLP" status={fzero} placement="top" />
                        <ServiceBadge serviceName="HEA" status={archive} placement="top" />
                        <ServiceBadge serviceName="HST" status={hos} placement="top" />
                    </HStack>
                    <HStack>
                        <ServiceBadge serviceName="HCP" status={hcp} placement="bottom" />
                        <ServiceBadge serviceName="CON" status={connect} placement="bottom" />
                        <ServiceBadge serviceName="FDLP" status={saascore} placement="bottom" />
                        <ServiceBadge serviceName="TAD" status={tadrill} placement="bottom" />
                    </HStack>
                </VStack>
            </Td>
        </Tr>

    )
}