import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Heading,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  SimpleGrid,
  Tag,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
  HStack,
  TableContainer,
  chakra,
  Skeleton,
  SkeletonCircle,
  Button,
  Spinner,
  Center,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react"
import Chart from 'react-apexcharts'

import { Header } from "./components/Header"
import { useSearchParams } from "react-router-dom";
import drive from "./assets/drive.svg"
import salesforce from "./assets/salesforce.svg"
import { TenantDetailCard } from './components/TenantDetailCard';
import { theme } from "./Theme";
import axios from 'axios';
import { useCallback, useEffect, useState } from "react"
import { API_ROOT, ENVIRONMENT } from './assets/const';
import { Customer, Services, DomainInfo, Domain, Graph, Remark, NumberOfLicensesType, StatusInformationResponse, DossunJobs } from './assets/types';
import { getApexChartsOptions, RedirectToLogin } from "./assets/utils";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { ServiceBadge } from "./components/ServiceBadge";
import { CopyButton } from "./components/CopyButton";
import SyntaxHighlighter from "react-syntax-highlighter";
import { ServiceTypeImage } from "./components/ServiceTypeImage";
import { TextModal } from "./components/TextModal";
import { CopyButtonWithLabel } from "./components/CopyButtonWithLabel";
import { NavigationMenu } from "./components/NavigationMenu";
import { DossunButton } from "./components/DossunButton";

export const TenantDetail = () => {
  const [searchParams,] = useSearchParams();
  const customer_id = searchParams.get("customer_id") ?? "";
  const [email, setEmail] = useState<string>("");

  const [customerData, setCustomerData] = useState<Customer | null>(null);
  const [customerDataIsLoading, setCustomerDataIsLoading] = useState<string>("loading");
  const [dossunJobsData, setDossunJobsData] = useState<DossunJobs | null>(null);
  const [statusInformationData, setStatusInformationData] = useState<StatusInformationResponse | null>(null);
  const [statusInformationLoadingStatus, setStatusInformationLoadingStatus] = useState<string>("loading");
  const [remarkStatus, setRemarkStatus] = useState<string>("");
  const [remarkStatusIsLoading, setRemarkStatusIsLoading] = useState<string>("loading");
  const [comparisonData, setComparisonData] = useState<string[][] | null>(null);
  const [customerDomains, setCustomerDomains] = useState<string[] | null>(null);
  const [customerDomainsIsLoading, setCustomerDomainsIsLoading] = useState<string>("loading");
  const [shobossoData, setShobossoData] = useState<Services | null>(null);
  const [shobossoDataIsLoading, setShobossoDataIsLoading] = useState<string>("loading");
  const [fzeroData, setFzeroData] = useState<Services | null>(null);
  const [fzeroDataIsLoading, setFzeroDataIsLoading] = useState<string>("loading");
  const [nobitaV1Data, setNobitaV1Data] = useState<Services | null>(null);
  const [nobitaV1DataIsLoading, setNobitaV1DataIsLoading] = useState<string>("loading");
  const [kumamushiV1Data, setKumamushiV1Data] = useState<Services | null>(null);
  const [kumamushiV1DataIsLoading, setKumamushiV1DataIsLoading] = useState<string>("loading");
  const [kumamushiV2Data, setKumamushiV2Data] = useState<Services | null>(null);
  const [kumamushiV2DataIsLoading, setKumamushiV2DataIsLoading] = useState<string>("loading");
  const [saascoreData, setSaascoreData] = useState<Services | null>(null);
  const [saascoreDataIsLoading, setSaascoreDataIsLoading] = useState<string>("loading");
  const [tadrillData, setTadrillData] = useState<Services | null>(null);
  const [tadrillDataIsLoading, setTadrillDataIsLoading] = useState<string>("loading");
  const [numberOfLicenses, setNumberOfLicenses] = useState<NumberOfLicensesType | null>(null);
  const [domainData, setDomainData] = useState<DomainInfo | null>(null);
  const [remarkData, setRemarkData] = useState<Remark | null>(null);
  const [remarkDataIsLoading, setRemarkDataIsLoading] = useState<string>("loading");
  const [kumamushiV1GraphData, setKumamushiV1GraphData] = useState<Graph | null>(null);
  const [kumamushiV2GraphData, setKumamushiV2GraphData] = useState<Graph | null>(null);
  const [hacGraphData, setHacGraphData] = useState<Graph | null>(null);
  const [nobitaAmountData, setNobitaAmountData] = useState<Graph | null>(null);
  const [fzeroFilterData, setFzeroFilterData] = useState<Graph | null>(null);
  const [karteLinks, setKarteLinks] = useState<object | null>(null);
  const [loadingCharts, setLoadingCharts] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  const handleUsageStatusClick = useCallback(() => {
    openInNewTab('/usage_status?customer_id=' + customer_id)
  }, [customer_id]);
  const { isOpen: isOpenKarte, onOpen: onOpenKarte, onClose: onCloseKarte } = useDisclosure()
  var parse = require('html-react-parser');


  useEffect(() => {

    axios.post(`${API_ROOT}/whoami`, {
      withCredentials: true
    })
      .then(response => {
        setLoading(false);
        setEmail(response.data)

        axios.get(`${API_ROOT}/customers/${customer_id}`)
          .then((response) => {
            setCustomerData(response.data);
            setCustomerDataIsLoading("loaded");
          }).catch(error => {
            console.log(error);
            setCustomerDataIsLoading("error");
          });

        axios.get(`${API_ROOT}/status_information/${customer_id}`)
          .then((response) => {
            setStatusInformationData(response.data);
            setStatusInformationLoadingStatus("loaded");
          }).catch(error => {
            console.log(error);
            setStatusInformationLoadingStatus("error");
          });

        axios.get(`${API_ROOT}/number_of_licenses/${customer_id}`)
          .then((response) => {
            setNumberOfLicenses(response.data);
          }).catch(error => {
            console.log(error);
          });

        axios.get(`${API_ROOT}/charts/${customer_id}`)
          .then((response) => {
            setLoadingCharts(false)
            setKarteLinks(response.data.karte_links)
            setHacGraphData(response.data.hac ? {
              options: getApexChartsOptions(
                "shobosso",
                "HAC usage",
                ['#00E395', '#ff4560'],
                response.data.hac.categories
              ),
              series: [{
                name: 'success',
                type: 'column',
                data: response.data.hac.success_count
              },
              {
                name: 'fail',
                type: 'column',
                data: response.data.hac.fail_count
              }]
            } : null);
            setNobitaAmountData(response.data.nobita ? {
              options: getApexChartsOptions(
                "nobita-amount",
                "Archive amount",
                ["#3399FF", "#33FF99", "#FEB019", "#FF4560"],
                response.data.nobita.categories
              ),
              series: [
                {
                  name: 'bsmtpd',
                  data: response.data.nobita.bsmtpd
                },
                {
                  name: 'jsmtpd',
                  data: response.data.nobita.jsmtpd
                },
                {
                  name: 'rsmtpd_in',
                  data: response.data.nobita.rsmtpd_in
                },
                {
                  name: 'rsmtpd_out',
                  data: response.data.nobita.rsmtpd_out
                },
              ]
            } : null);
            setFzeroFilterData(response.data.fzero ? {
              options: getApexChartsOptions(
                "fzero",
                "DLP usage",
                ["#3399FF", "#33FF99", "#FEB019", "#FF4560"],
                response.data.fzero.categories
              ),
              series: [
                {
                  name: 'Send',
                  data: response.data.fzero.send
                },
                {
                  name: 'Suspend',
                  data: response.data.fzero.suspend
                },
                {
                  name: 'Request Approval',
                  data: response.data.fzero.request_approval
                },
                {
                  name: 'Delete',
                  data: response.data.fzero.delete
                },
              ]
            } : null);
            setKumamushiV1GraphData(response.data.kumamushi_v1 ? {
              options: getApexChartsOptions(
                "kumamushi_v1_graph",
                "HST v1 usage",
                ["#3399FF", "#33FF99"],
                response.data.kumamushi_v1.categories
              ),
              series: [
                {
                  name: 'sbundle count',
                  data: response.data.kumamushi_v1.sbundle_count
                },
                {
                  name: 'rbundle count',
                  data: response.data.kumamushi_v1.rbundle_count
                },
              ]
            } : null);
            setKumamushiV2GraphData(response.data.kumamushi_v2 ? {
              options: getApexChartsOptions(
                "kumamushi_v2_graph",
                "HST v2 usage",
                ["#3399FF", "#33FF99", "#FEB019"],
                response.data.kumamushi_v2.categories
              ),
              series: [
                {
                  name: 'HSD / Send',
                  data: response.data.kumamushi_v2.secure_download_send
                },
                {
                  name: 'HST / Send',
                  data: response.data.kumamushi_v2.secure_transfer_send
                },
                {
                  name: 'HST / Receive',
                  data: response.data.kumamushi_v2.secure_transfer_receive
                },
              ]
            } : null);
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              setLoadingCharts(false)
              setKarteLinks({})
            }
            console.log(error);
          })

        axios.get(`${API_ROOT}/domain/${customer_id}`)
          .then((response) => {
            setDomainData(response.data);
          }).catch(error => {
            console.log(error);
          });


        axios.get(`${API_ROOT}/customer_domains/${customer_id}`)
          .then((response) => {
            setCustomerDomains(response.data);
            setCustomerDomainsIsLoading("loaded")
          }).catch(error => {
            console.log(error);
            setCustomerDomainsIsLoading("error")
          });

        axios.get(`${API_ROOT}/services_shobosso/${customer_id}`)
          .then((response) => {
            setShobossoData(response.data);
            setShobossoDataIsLoading("loaded")
          }).catch(error => {
            if (error.response && error.response.status === 404) {
              setShobossoDataIsLoading("loaded")
            } else {
              console.log(error);
              setShobossoDataIsLoading("error")
            }
          });

        axios.get(`${API_ROOT}/services_fzero/${customer_id}`)
          .then((response) => {
            setFzeroData(response.data);
            setFzeroDataIsLoading("loaded")
          }).catch(error => {
            if (error.response && error.response.status === 404) {
              setFzeroDataIsLoading("loaded")
            } else {
              console.log(error);
              setFzeroDataIsLoading("error")
            }
          });

        axios.get(`${API_ROOT}/services_nobita_v1/${customer_id}`)
          .then((response) => {
            setNobitaV1Data(response.data);
            setNobitaV1DataIsLoading("loaded")
          }).catch(error => {
            if (error.response && error.response.status === 404) {
              setNobitaV1DataIsLoading("loaded")
            } else {
              console.log(error);
              setNobitaV1DataIsLoading("error")
            }
          });

        axios.get(`${API_ROOT}/services_kumamushi_v1/${customer_id}`)
          .then((response) => {
            setKumamushiV1Data(response.data);
            setKumamushiV1DataIsLoading("loaded")
          }).catch(error => {
            if (error.response && error.response.status === 404) {
              setKumamushiV1DataIsLoading("loaded")
            } else {
              console.log(error);
              setKumamushiV1DataIsLoading("error")
            }
          });

        axios.get(`${API_ROOT}/services_kumamushi_v2/${customer_id}`)
          .then((response) => {
            setKumamushiV2Data(response.data);
            setKumamushiV2DataIsLoading("loaded")
          }).catch(error => {
            if (error.response && error.response.status === 404) {
              setKumamushiV2DataIsLoading("loaded")
            } else {
              console.log(error);
              setKumamushiV2DataIsLoading("error")
            }
          });

        axios.get(`${API_ROOT}/services_saascore/${customer_id}`)
          .then((response) => {
            setSaascoreData(response.data);
            setSaascoreDataIsLoading("loaded")
          }).catch(error => {
            if (error.response && error.response.status === 404) {
              setSaascoreDataIsLoading("loaded")
            } else {
              console.log(error);
              setSaascoreDataIsLoading("error")
            }
          });

        axios.get(`${API_ROOT}/services_tadrill/${customer_id}`)
          .then((response) => {
            setTadrillData(response.data);
            setTadrillDataIsLoading("loaded")
          }).catch(error => {
            if (error.response && error.response.status === 404) {
              setTadrillDataIsLoading("loaded")
            } else {
              console.log(error);
              setTadrillDataIsLoading("error")
            }
          });

        axios.get(`${API_ROOT}/remark/${customer_id}`)
          .then((response) => {
            setRemarkData(response.data);
            setRemarkDataIsLoading("loaded")
          }).catch(error => {
            console.log(error);
            setRemarkDataIsLoading("error")
          });

      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          RedirectToLogin();
        }
      });
  }, [customer_id]);

  useEffect(() => {
    if ([customerDataIsLoading, shobossoDataIsLoading, fzeroDataIsLoading, nobitaV1DataIsLoading, remarkDataIsLoading].some(status => status === "loading")) {
      return;
    } else if ([customerDataIsLoading, shobossoDataIsLoading, fzeroDataIsLoading, nobitaV1DataIsLoading, remarkDataIsLoading].some(status => status === "error")) {
      setRemarkStatus("Failed to load remark information");
      setRemarkStatusIsLoading("loaded");
      return;
    }
    let tmpRemarkStatusEn = "";
    let tmpRemarkStatusJa = "";
    if (shobossoData?.has_remark) {
      tmpRemarkStatusEn += "HENNGE Access Control ";
      tmpRemarkStatusJa += "HENNGE Access Control ";
    }
    if (fzeroData?.has_remark) {
      if (tmpRemarkStatusEn !== "") {
        tmpRemarkStatusEn += ", ";
        tmpRemarkStatusJa += "、";
      }
      tmpRemarkStatusEn += "HENNGE Email DLP";
      tmpRemarkStatusJa += "HENNGE Email DLP";
    }
    if (nobitaV1Data?.has_remark) {
      if (tmpRemarkStatusEn !== "") {
        tmpRemarkStatusEn += ", ";
        tmpRemarkStatusJa += "、";
      }
      tmpRemarkStatusEn += "HENNGE Email Archive";
      tmpRemarkStatusJa += "HENNGE Email Archive";
    }
    if (remarkData?.csegdb_one_tenant) {
      if (tmpRemarkStatusEn !== "") {
        tmpRemarkStatusEn += ", ";
        tmpRemarkStatusJa += "、";
      }
      tmpRemarkStatusEn += "CSEGDB One Tenant";
      tmpRemarkStatusJa += "CSEGDB One Tenant";
    }
    if (remarkData?.csegdb_account) {
      if (tmpRemarkStatusEn !== "") {
        tmpRemarkStatusEn += ", ";
        tmpRemarkStatusJa += "、";
      }
      tmpRemarkStatusEn += "CSEGDB Account";
      tmpRemarkStatusJa += "CSEGDB Account";
    }
    if (remarkData?.sfdc_memo) {
      if (tmpRemarkStatusEn !== "") {
        tmpRemarkStatusEn += ", ";
        tmpRemarkStatusJa += "、";
      }
      tmpRemarkStatusEn += "SFDC Memo";
      tmpRemarkStatusJa += "SFDC Memo";
    }
    if (remarkData?.sfdc_irregular_details) {
      if (tmpRemarkStatusEn !== "") {
        tmpRemarkStatusEn += ", ";
        tmpRemarkStatusJa += "、";
      }
      tmpRemarkStatusEn += "SFDC Irregular Details";
      tmpRemarkStatusJa += "SFDC Irregular Details";
    }
    if (tmpRemarkStatusEn !== "") {
      tmpRemarkStatusEn = "This tenant has remarks for " + tmpRemarkStatusEn + ".\nPlease check the remarks for details.";
      tmpRemarkStatusJa = "このテナントは" + tmpRemarkStatusJa + "に注意事項があります。\nリテンション訪問やサポート時には、必ず注意事項を確認してください。";
    }
    if (customerData?.is_irregular) {
      if (tmpRemarkStatusEn !== "") {
        tmpRemarkStatusEn += "\n\n";
        tmpRemarkStatusJa += "\n\n";
      }
      tmpRemarkStatusEn += "This tenant is an irregular tenant.";
      tmpRemarkStatusJa += "このテナントはイレギュラーテナントです。";
    }
    if (tmpRemarkStatusEn !== "") {
      setRemarkStatus(tmpRemarkStatusEn + "\n\n\n" + tmpRemarkStatusJa);
    }
    setRemarkStatusIsLoading("loaded");
  }, [customerData, shobossoData, fzeroData, nobitaV1Data, remarkData, customerDataIsLoading, shobossoDataIsLoading, fzeroDataIsLoading, nobitaV1DataIsLoading, remarkDataIsLoading]);


  useEffect(() => {
    if ([customerDomainsIsLoading, shobossoDataIsLoading, fzeroDataIsLoading, nobitaV1DataIsLoading, kumamushiV1DataIsLoading, kumamushiV2DataIsLoading].some(status => status === "loading")) {
      return;
    }
    setComparisonData(comparisonDataResult(customerDomains, shobossoData, fzeroData, nobitaV1Data, kumamushiV1Data, kumamushiV2Data));
  }, [customerDomains, shobossoData, fzeroData, nobitaV1Data, kumamushiV1Data, kumamushiV2Data, customerDomainsIsLoading, shobossoDataIsLoading, fzeroDataIsLoading, nobitaV1DataIsLoading, kumamushiV1DataIsLoading, kumamushiV2DataIsLoading]);

  useEffect(() => {
    axios.get(`${API_ROOT}/dossun_jobs`)
      .then((response) => {
        setDossunJobsData(response.data.jobs);
      }).catch(error => {
        console.log(error);
      });
  }, []);

  function comparisonDataResult(customerDomains: string[] | null, shobossoData: Services | null, fzeroData: Services | null, nobitaV1Data: Services | null, kumamushiV1Data: Services | null, kumamushiV2Data: Services | null) {
    const all_domains = Array.from(new Set([
      ...(customerDomains ?? []),
      ...(shobossoData?.allowed_google_domains ?? []),
      ...(fzeroData?.fzero_allowed_domains ?? []),
      ...(nobitaV1Data?.allowed_envelope_domains ?? []),
      ...(nobitaV1Data?.allowed_originator_orgs ?? []),
      ...(kumamushiV1Data?.kumamushi_v1_domains ?? []),
      ...(kumamushiV2Data?.kumamushi_v2_domains ?? []),
    ]));

    const result: string[][] = [
      [
        "Customer Domain",
        "Allowed Google Domain",
        "DLP Allowed Domain",
        "HEA Allowed Envelop Domain",
        "HEA Allowed Originator Org",
        "Kumamushi v1 Tenant",
        "Kumamushi v2 Domain",
      ]
    ];

    all_domains.sort().forEach(domain => {
      result.push([
        domain,
        customerDomains?.includes(domain) ? "〇" : "-",
        shobossoData?.allowed_google_domains?.includes(domain) ? "〇" : "-",
        fzeroData?.fzero_allowed_domains?.includes(domain) ? "〇" : "-",
        nobitaV1Data?.allowed_envelope_domains?.includes(domain) ? "〇" : "-",
        nobitaV1Data?.allowed_originator_orgs?.includes(domain) ? "〇" : "-",
        kumamushiV1Data?.kumamushi_v1_domains_ox?.[domain] ?? "-",
        kumamushiV2Data?.kumamushi_v2_domains_ox?.[domain] ?? "-",
      ]);
    });

    return result;
  }

  if (loading) {
    return <>Loading...</>
  }

  return (
    <ChakraProvider theme={theme}>
      <Header user_email={email} />
      <NavigationMenu />
      <Stack id="title" fontSize="md" px={40} py={8} spacing={10} as="main" pt="20">
        <Stack fontSize="md" spacing={4}>
          <HStack spacing={4}>
            <Heading as="h1" size="xl" color="grey.700">{customer_id}</Heading>
            {customer_id ? <CopyButton text={customer_id} /> : <></>}
            <Button colorScheme='yellow' variant='outline' size="xs" _focus={{ boxShadow: "none" }} onClick={handleUsageStatusClick} >
              Usage status
            </Button>
            <DossunButton customer_id={customer_id} dossunJobs={dossunJobsData} />
          </HStack>
          <HStack>
            <Heading size="md" color="grey.700">
              {customerData ? customerData.service_id :
                <Skeleton width={120} height={6} />}
            </Heading>
            {customerData ? <CopyButton text={customerData.service_id} /> : <></>}
          </HStack>
          <HStack>
            <Heading size="md" color="grey.700">
              {customerData ? customerData.company_name_ja :
                <Skeleton width={150} height={6} />}
            </Heading>
            {customerData ? <CopyButton text={customerData.company_name_ja} /> : <></>}
          </HStack>
          <HStack>
            <Heading size="md" color="grey.700">
              {customerData ? customerData.company_name_en :
                <Skeleton width={180} height={6} />}
            </Heading>
            {customerData ? <CopyButton text={customerData.company_name_en} /> : <></>}
          </HStack>
        </Stack>
        <SimpleGrid columns={5} spacing={8}>
          <VStack shadow="md" bg="white" p={8} pb={10} spacing={4}>
            <Text as="h2" fontSize={14} color="grey">Service type</Text>
            {customerData ?
              <Heading as="h2" fontSize={20} size="lg" >{customerData.service_type}</Heading> : <Skeleton width={120} height={6} />
            }
            {domainData ? domainData.both_service_use ? <Text>{domainData.both_service_use}</Text> : <></> : <VStack><Text>checking if both service use</Text><Spinner size="xs" /></VStack>}
          </VStack>

          <VStack shadow="md" bg="white" p={8} pb={10} spacing={4}>
            <Text as="h2" fontSize={14} color="grey">Connection Date</Text>
            {customerData ?
              <Heading as="h2" fontSize={20} size="lg" >{customerData.connection_date}</Heading> : <Skeleton width={120} height={6} />
            }
          </VStack>
          <VStack shadow="md" bg="white" p={8} pb={10} spacing={4}>
            <Text as="h2" fontSize={14} color="grey">Status</Text>
            {customerData ?
              <Heading as="h2" fontSize={20} size="lg" >{customerData.env}</Heading> : <Skeleton width={120} height={6} />
            }
          </VStack>
          <VStack shadow="md" bg="white" p={8} pb={4} spacing={0}>
            <HStack><Text as="h2" fontSize={14} color="grey">Links</Text> <ExternalLinkIcon mx='2px' /></HStack>
            <HStack alignItems="center" >
              {customerData ? customerData.id ? <a href={`https://hennge.my.salesforce.com/${customerData.id}`} target="_blank" rel="noreferrer" >   <chakra.img boxSize={50} src={salesforce} /></a> : <Text>-</Text> : <SkeletonCircle size="10" />}
              <Box width={4} />
              {customerData ? customerData.gdrive_url ? <a href={customerData.gdrive_url} target="_blank" rel="noreferrer" >    <chakra.img boxSize={50} src={drive} /></a> : <Text>-</Text> : <SkeletonCircle size="10" />}
            </HStack>
          </VStack>
          <VStack shadow="md" bg="white" p={8} pb={10} spacing={4}>
            <Text as="h2" fontSize={14} color="grey">Support type</Text>
            {customerData ? customerData.support_type ? <Tag variant='solid' colorScheme='blue' >HENNGE One Support</Tag> : <Text>-</Text> : <Skeleton width={120} height={6} />}
          </VStack>
        </SimpleGrid>
        {statusInformationLoadingStatus === "error" && <Text>⚠️ Failed to load status information</Text>}
        {
          statusInformationLoadingStatus === "loaded" && statusInformationData && statusInformationData.account && <Box shadow="md" bg="white" p={4}>
            <VStack spacing={4}>
              <Text fontSize={14} color="grey">{statusInformationData.account.name}</Text>
              <HStack spacing={8}>
                <Text fontWeight="bold" fontSize="3xl">
                  {statusInformationData.account.status}
                </Text>
                <Box>
                  <Text>
                    {statusInformationData.account.status_message}
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </Box>
        }
        {
          remarkStatusIsLoading === "loaded" && remarkStatus ? <Box shadow="md" bg="red.100" p={4} >
            <Text whiteSpace="pre-line">
              {remarkStatus}
            </Text>
          </Box> : remarkStatusIsLoading === "loading" ? <Center><Spinner /></Center> : <></>
        }
        {
          statusInformationLoadingStatus === "loaded" && statusInformationData && statusInformationData.end_user_account && <Box shadow="md" bg="white" p={4}>
            <VStack spacing={4}>
              <Text fontSize={14} color="grey">{statusInformationData.end_user_account.name}</Text>
              <HStack spacing={8}>
                <Text fontWeight="bold" fontSize="3xl">
                  {statusInformationData.end_user_account.status}
                </Text>
                <Box>
                  <Text>
                    {statusInformationData.end_user_account.status_message}
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </Box>
        }
        {customerData ?
          <Table variant='simple' backgroundColor="white" shadow="md">
            <Thead>
              <Tr>
                <Th><Center>Service Name</Center></Th>
                <Th><Center>Status</Center></Th>
                <Th><Center>Number of licenses</Center></Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td><Center><ServiceBadge status={customerData.hac} serviceName="HAC" /></Center></Td>
                <Td><Center>{customerData.hac}</Center></Td>
                <Td><Center>{numberOfLicenses ? numberOfLicenses.hac : <Skeleton width={30} height={6} />}</Center></Td>
              </Tr>
              <Tr>
                <Td><Center><ServiceBadge status={customerData.fzero} serviceName="DLP" /></Center></Td>
                <Td><Center>{customerData.fzero}</Center></Td>
                <Td><Center>{numberOfLicenses ? numberOfLicenses.fzero : <Skeleton width={30} height={6} />}</Center></Td>
              </Tr>
              <Tr>
                <Td><Center><ServiceBadge status={customerData.archive} serviceName="HEA" /></Center></Td>
                <Td><Center>{customerData.archive}</Center></Td>
                <Td><Center>{numberOfLicenses ? numberOfLicenses.archive : <Skeleton width={30} height={6} />}</Center></Td>
              </Tr>
              <Tr>
                <Td><Center><ServiceBadge status={customerData.hos} serviceName="HST" /></Center></Td>
                <Td><Center>{customerData.hos}</Center></Td>
                <Td><Center>{numberOfLicenses ? numberOfLicenses.hos : <Skeleton width={30} height={6} />}</Center></Td>
              </Tr>
              <Tr>
                <Td><Center><ServiceBadge status={customerData.hcp} serviceName="HCP" /></Center></Td>
                <Td><Center>{customerData.hcp}</Center></Td>
                <Td><Center>{numberOfLicenses ? numberOfLicenses.hcp : <Skeleton width={30} height={6} />}</Center></Td>
              </Tr>
              <Tr>
                <Td><Center><ServiceBadge status={customerData.connect} serviceName="Connect" /></Center></Td>
                <Td><Center>{customerData.connect}</Center></Td>
                <Td><Center>-</Center></Td>
              </Tr>
              <Tr>
                <Td><Center><ServiceBadge status={customerData.saascore} serviceName="File DLP" /></Center></Td>
                <Td><Center>{customerData.saascore}</Center></Td>
                <Td><Center>{numberOfLicenses ? numberOfLicenses.saascore : <Skeleton width={30} height={6} />}</Center></Td>
              </Tr>
              <Tr>
                <Td><Center><ServiceBadge status={customerData.tadrill} serviceName="Tadrill" /></Center></Td>
                <Td><Center>{customerData.tadrill}</Center></Td>
                <Td><Center>{numberOfLicenses ? numberOfLicenses.tadrill : <Skeleton width={30} height={6} />}</Center></Td>
              </Tr>

            </Tbody>
          </Table>
          : <Center><Spinner /></Center>}
        <SimpleGrid columns={1} spacing={10}>

          <Accordion id="domains" defaultIndex={[0]} allowMultiple>
            <AccordionItem shadow="md" bg="white">
              <h2>
                <Stack direction="row">
                  <HStack>
                    <Heading as="h2" size="md" p={4}>
                      Domains
                    </Heading>
                    {domainData && <CopyButtonWithLabel label="Copy All Domains" text={domainData.all_domains_str} m={0} />}
                    {domainData && <CopyButtonWithLabel label="Copy Valid Domains" text={domainData.valid_domains_str} m={0} />}
                  </HStack>
                  <Spacer />
                  <AccordionButton width="100" _focus={{ boxShadow: "none" }} >
                    <AccordionIcon />
                  </AccordionButton></Stack>
              </h2>
              <AccordionPanel pb={4}>
                <Stack>
                  <TableContainer>
                    <Table variant='simple'>
                      <Thead >
                        <Tr>
                          <Th>Domain</Th>
                          <Th>Main</Th>
                          <Th>Status</Th>
                          <Th>Document Only</Th>
                          <Th>MX</Th>
                          <Th>SPF</Th>
                          <Th>SSO Service</Th>
                          <Th>Remark</Th>
                        </Tr>
                      </Thead>
                      {
                        domainData ? <Tbody>
                          {Object.values(domainData.domains).map((value: Domain, index: number) => <Tr key={index}>
                            <Td><HStack><Text>{value.domain}</Text><CopyButton text={value.domain} /></HStack></Td>
                            <Td><Center>{value.main}</Center></Td>
                            <Td><Center>{value.status}</Center></Td>
                            <Td><Center>{value.document_only}</Center></Td>
                            <Td><Center>{value.mx}</Center></Td>
                            <Td><Center>{value.spf}</Center></Td>
                            <Td><Center><ServiceTypeImage service_type={value.service_type} /></Center></Td>
                            <Td><Center><TextModal text={value.remark} show_length={5} title="Remark" /></Center></Td>
                          </Tr>
                          )}
                        </Tbody> :
                          <Tbody>
                            <Tr>
                              <Td> <Skeleton width={200} height={4} /></Td>
                              <Td><Center><Skeleton width={50} height={4} /></Center></Td>
                              <Td ><Center><Skeleton width={50} height={4} /></Center></Td>
                              <Td ><Center><Skeleton width={50} height={4} /></Center></Td>
                              <Td ><Center><Skeleton width={50} height={4} /></Center></Td>
                              <Td ><Center><Skeleton width={50} height={4} /></Center></Td>
                              <Td ><Center><Skeleton width={50} height={4} /></Center></Td>
                              <Td ><Center><Skeleton width={50} height={4} /></Center></Td>
                            </Tr>

                          </Tbody>}
                    </Table>
                  </TableContainer>
                  <Spacer />
                  {domainData ?
                    <Stack>
                      <Accordion allowToggle>
                        <AccordionItem >
                          <AccordionButton width="50" _focus={{ boxShadow: "none" }}>
                            <HStack>
                              <AccordionIcon />
                              <Text>Oukasho Customer Table</Text>
                              <Spacer />
                            </HStack>
                          </AccordionButton>
                          <AccordionPanel pb={6}>
                            <SyntaxHighlighter language="javascript" >
                              {domainData.oukasho_customer_table}
                            </SyntaxHighlighter>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                      <Accordion allowToggle>
                        <AccordionItem >
                          <AccordionButton width="50" _focus={{ boxShadow: "none" }}>
                            <HStack>
                              <AccordionIcon />
                              <Text>Mapping of Services and Domains</Text>
                              <Spacer />
                            </HStack>
                          </AccordionButton>
                          <AccordionPanel pb={6}>
                            <SyntaxHighlighter language="javascript" >
                              {domainData.service_domains}
                            </SyntaxHighlighter>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </Stack>
                    : <Stack>
                      <Skeleton width={100} height={4} />
                      <Skeleton width={100} height={4} />
                    </Stack>
                  }


                  {comparisonData ?
                    <Accordion allowToggle>
                      <AccordionItem >
                        <AccordionButton width="50" _focus={{ boxShadow: "none" }}>
                          <HStack>
                            <AccordionIcon />
                            <Text>Compare Domains</Text>
                            <Spacer />
                          </HStack>
                        </AccordionButton>
                        <AccordionPanel pb={6}>
                          <TableContainer>
                            <Table variant='simple'>
                              <Tbody>
                                <Tr><Td></Td>{Array.from(comparisonData[0]).map((td, number) => <Td backgroundColor="teal.500" color="white" key={number}>{td}</Td>)}</Tr>
                                {Array.from(comparisonData.slice(1)).map((row, trNumber) => <Tr key={trNumber}>
                                  <Td backgroundColor="grey" color="white">{row[0]}</Td>
                                  {Array.from(row.slice(1)).map((td, tdNumber) => <Td key={tdNumber}><Center>{td}</Center></Td>)}
                                </Tr>
                                )}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                    : <Skeleton width={100} height={4} />}
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Box shadow="md" bg="white" p={4}>
            <Stack><SimpleGrid columns={3} >
              <VStack>
                <Skeleton isLoaded={!loadingCharts} width={150} height={loadingCharts ? 4 : 0} />
                <Skeleton isLoaded={!loadingCharts} width={300} height={loadingCharts ? 220 : 0} />
              </VStack>
              <VStack>
                <Skeleton isLoaded={!loadingCharts} width={150} height={loadingCharts ? 4 : 0} />
                <Skeleton isLoaded={!loadingCharts} width={300} height={loadingCharts ? 220 : 0} />
              </VStack>
              <VStack>
                <Skeleton isLoaded={!loadingCharts} width={150} height={loadingCharts ? 4 : 0} />
                <Skeleton isLoaded={!loadingCharts} width={300} height={loadingCharts ? 220 : 0} />
              </VStack>

              {hacGraphData ?
                <Chart options={hacGraphData.options} series={hacGraphData.series} type="bar" width={330} height={220} />
                : <></>}
              {fzeroFilterData ?
                <Chart options={fzeroFilterData.options} series={fzeroFilterData.series} type="bar" width={330} height={220} />
                : <></>}
              {nobitaAmountData ?
                <Chart options={nobitaAmountData.options} series={nobitaAmountData.series} type="bar" width={330} height={220} />
                : <></>}
              {kumamushiV1GraphData ?
                <Chart options={kumamushiV1GraphData.options} series={kumamushiV1GraphData.series} type="bar" width={330} height={220} />
                : <></>}
              {kumamushiV2GraphData ?
                <Chart options={kumamushiV2GraphData.options} series={kumamushiV2GraphData.series} type="bar" width={330} height={220} />
                : <></>}

            </SimpleGrid>
              <Spacer />
              {karteLinks ? Object.keys(karteLinks).length !== 0 ? <Link color="green" onClick={onOpenKarte}>See more details in DataScope Karte</Link> : <Text>No data</Text> : <Skeleton width={100} height={4} />}
              <Modal onClose={onCloseKarte} isOpen={isOpenKarte} scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    Links to Karte
                  </ModalHeader>
                  <ModalCloseButton _focus={{ boxShadow: "none" }} />
                  <ModalBody>
                    {
                      karteLinks ?
                        <Stack>
                          {
                            Object.entries(karteLinks).map((domain_info_item, index) =>
                              <Link key={index} href={domain_info_item[1]} isExternal>
                                <HStack>
                                  <Text color="green">{domain_info_item[0]}</Text>
                                  <ExternalLinkIcon mx='2px' />
                                </HStack>
                              </Link>)
                          }
                        </Stack> : <Spinner />
                    }
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={onCloseKarte} _focus={{ boxShadow: "none" }}>Close</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Stack>
          </Box>

          <TenantDetailCard title="HENNGE Access Control" serviceName="shobosso" servicesData={shobossoData} loadingStatus={shobossoDataIsLoading} customer_id={customer_id} dossunJobs={dossunJobsData} />
          <TenantDetailCard title="HENNGE Email DLP" serviceName="fzero" servicesData={fzeroData} loadingStatus={fzeroDataIsLoading} customer_id={customer_id} dossunJobs={dossunJobsData} />
          <TenantDetailCard title="HENNGE Email Archive" serviceName="nobita_v1" servicesData={nobitaV1Data} loadingStatus={nobitaV1DataIsLoading} customer_id={customer_id} dossunJobs={dossunJobsData} />
          <TenantDetailCard title="HENNGE Secure Transfer v1" serviceName="kumamushi_v1" servicesData={kumamushiV1Data} loadingStatus={kumamushiV1DataIsLoading} customer_id={customer_id} dossunJobs={dossunJobsData} />
          <TenantDetailCard title="HENNGE Secure Transfer v2" serviceName="kumamushi_v2" servicesData={kumamushiV2Data} loadingStatus={kumamushiV2DataIsLoading} customer_id={customer_id} dossunJobs={dossunJobsData} />
          <TenantDetailCard title="File DLP" serviceName="saascore" servicesData={saascoreData} loadingStatus={saascoreDataIsLoading} customer_id={customer_id} dossunJobs={dossunJobsData} />
          <TenantDetailCard title="Tadrill" serviceName="tadrill" servicesData={tadrillData} loadingStatus={tadrillDataIsLoading} customer_id={customer_id} dossunJobs={dossunJobsData} />
          <Box id="remark" shadow="md" bg="white" p={4}>
            <Stack spacing={8}>
              <Heading as="h2" size="md" >
                Remarks
              </Heading>
              <Stack>
                <Heading as="h4" size="sm">
                  CSEGDB One Tenant
                </Heading>
                {
                  remarkData ?
                    remarkData.csegdb_one_tenant ?
                      <Text backgroundColor="red.100" p={4} style={{ whiteSpace: "pre-line" }}> {remarkData.csegdb_one_tenant} </Text> :
                      <Text>No data</Text> :
                    <Center><Spinner /></Center>
                }
              </Stack>
              <Stack>
                <Heading as="h4" size="sm">
                  CSEGDB Account
                </Heading>
                {
                  remarkData ?
                    remarkData.csegdb_account ?
                      <Text backgroundColor="red.100" p={4} style={{ whiteSpace: "pre-line" }}> {remarkData.csegdb_account} </Text> :
                      <Text>No data</Text> :
                    <Center><Spinner /></Center>
                }
              </Stack>
              <Stack>
                <Heading as="h4" size="sm">
                  SFDC Memo
                </Heading>
                {
                  remarkData ?
                    remarkData.sfdc_memo ?
                      <Text backgroundColor="red.100" p={4} style={{ whiteSpace: "pre-line" }}> {parse(remarkData.sfdc_memo)} </Text> :
                      <Text>No data</Text> :
                    <Center><Spinner /></Center>
                }
              </Stack>
              <Stack>
                <Heading as="h4" size="sm">
                  SFDC Irregular Details
                </Heading>
                {
                  remarkData ?
                    remarkData.sfdc_irregular_details ?
                      <Text backgroundColor="red.100" p={4} style={{ whiteSpace: "pre-line" }}> {parse(remarkData.sfdc_irregular_details)} </Text> :
                      <Text>No data</Text> :
                    <Center><Spinner /></Center>
                }
              </Stack>
            </Stack>
          </Box>
        </SimpleGrid>
      </Stack>
    </ChakraProvider>
  )
}
