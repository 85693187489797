import {
    Table,
    Tr,
    Td,
    Tbody,
} from "@chakra-ui/react"
import { KumamushiV2Object } from './assets/types';
var CryptoJS = require("crypto-js");

export const HST2DiffsTable = () => {
    const kumamushiV2TableKeys: Array<string> = CryptoJS.AES.decrypt(localStorage.getItem("kumamushiV2TableKeys") || "", "diffs").toString(CryptoJS.enc.Utf8).split(",");
    const kumamushiV2TableObjects: Array<KumamushiV2Object> = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("kumamushiV2TableObjects") || "", "diffs").toString(CryptoJS.enc.Utf8));
    return <Table variant='simple'>
        <Tbody>
            {Array.from(kumamushiV2TableKeys).map((key, number) =>
                <Tr key={number}>
                    <Td backgroundColor="grey" color="white">{key}</Td>
                    {Object.values(kumamushiV2TableObjects).map((object: KumamushiV2Object, index: number) =>
                        <Td key={index} backgroundColor="white" css={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                            {object[key] === undefined ? "-" : String(object[key])}
                        </Td>)}
                </Tr>
            )}
        </Tbody>
    </Table>
}