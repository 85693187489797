import { HStack, Text } from "@chakra-ui/react"

import { CopyButton } from "./CopyButton";


type TextWithCopyButtonProps = {
    text: string;
}

export const TextWithCopyButton = ({ text }: TextWithCopyButtonProps) => {

    return <HStack><Text>{text}</Text><CopyButton text={text} /></HStack>
}

