import { Link } from "react-scroll";
import { Flex, Box } from "@chakra-ui/react";

export const NavigationMenu = () => {

    const linkStyles = {
        cursor: "pointer",
        padding: "8px",
        borderRadius: "4px",
    };
    return (
        <Box
            position="fixed"
            right="0"
            top="20%"
            zIndex="1"
            margin={4}
        >
            <Flex flexDirection="column" alignItems="flex-end">
                <Link to="title" style={linkStyles}>
                    Top
                </Link>
                <Link to="domains" offset={-100} style={linkStyles}>
                    Domains
                </Link>
                <Link to="shobosso" offset={-100} style={linkStyles}>
                    HAC
                </Link>
                <Link to="fzero" offset={-100} style={linkStyles}>
                    DLP
                </Link>
                <Link to="nobita_v1" offset={-100} style={linkStyles}>
                    ARC
                </Link>
                <Link to="kumamushi_v1" offset={-100} style={linkStyles}>
                    HST v1
                </Link>
                <Link to="kumamushi_v2" offset={-100} style={linkStyles}>
                    HST v2
                </Link>
                <Link to="saascore" offset={-100} style={linkStyles}>
                    File DLP
                </Link>
                <Link to="tadrill" offset={-100} style={linkStyles}>
                    Tadrill
                </Link>
                <Link to="remark" offset={-100} style={linkStyles}>
                    Remark
                </Link>
            </Flex>
        </Box>
    );
};