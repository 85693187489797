import { chakra, HStack, Text } from "@chakra-ui/react"

import o365 from "../assets/o365.svg"
import google from "../assets/google.svg"


type ServiceTypeImageProps = {
    service_type: string;
}
export const ServiceTypeImage = ({ service_type }: ServiceTypeImageProps) => {

    return service_type === "microsoft,google" ?
        <HStack>
            <chakra.img src={google} boxSize="6" />
            <chakra.img src={o365} boxSize="6" />
        </HStack> :
        service_type === "google" ?
            <chakra.img src={google} boxSize="6" /> :
            service_type === "microsoft" ?
                <chakra.img src={o365} boxSize="6" /> :
                <Text>-</Text>
}

